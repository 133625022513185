import getEnvironment from '../../utils/functions/getEnvironment';

const title = 'Tracking => SalesForce SDK';

const log = (message) => console.log(`%c${message}`, 'font-weight: bold; font-size: 15px;');

const isProduction = getEnvironment() === 'production';

const hasSDK = () =>
  !!window?.SalesForceAnalytics?.track || !!window?.webkit?.messageHandlers?.salesForceTrack?.postMessage;

const identify = (profileId) => {
  window?.SalesForceAnalytics?.identify?.(profileId) ||
    window?.webkit?.messageHandlers?.salesForceIdentify?.postMessage?.(profileId);

  if (!isProduction) {
    let message = title;
    message += `\nIdentify Profile ID:\n- ${profileId}`;
    log(message);
  }
};

const track = (name, properties = {}) => {
  window?.SalesForceAnalytics?.track?.(JSON.stringify({ eventName: name, eventAttributes: properties })) ||
    window?.webkit?.messageHandlers?.salesForceTrack?.postMessage?.({ eventName: name, eventAttributes: properties });

  if (!isProduction) {
    let message = title;
    message += `\nEvent Name:\n- ${name}`;
    message += `\nEvent Properties (${Object.keys(properties).length}):`;

    for (const property in properties) {
      message += `\n- ${property}: ${properties[property]}`;
    }

    log(message);
  }
};

const peopleSet = (properties = {}) => {
  window?.SalesForceAnalytics?.peopleSet?.(JSON.stringify(properties)) ||
    window?.webkit?.messageHandlers?.salesForcePeopleSet?.postMessage?.(properties);

  if (!isProduction) {
    let message = title;
    message += `\nRegister People Properties`;
    message += `\nProperties (${Object.keys(properties).length}):`;

    for (const property in properties) {
      message += `\n- ${property}: ${properties[property]}`;
    }

    log(message);
  }
};

export default {
  hasSDK,
  identify,
  track,
  peopleSet,
};
