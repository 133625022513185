import Bugsnag from '@bugsnag/browser';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const onError = (event) => {
  if (multiStorage) {
    if (multiStorage.getItem('location')) event.addMetadata('location', JSON.parse(multiStorage.getItem('location')));
    if (multiStorage.getItem('user')) event.setUser(JSON.parse(multiStorage.getItem('user')).id);

    event.addMetadata('user', {
      cartToken: multiStorage.getItem('cartToken'),
      deviceToken: multiStorage.getItem('deviceToken'),
      appIdentifier: multiStorage.getItem('appIdentifier'),
      shopping: multiStorage.getItem('shopping') ? JSON.parse(multiStorage.getItem('shopping')).id : null,
    });
  }
};

const options = {
  apiKey: process.env.BUGSNAG_KEY,
  appVersion: VERSION,
  appType: 'app_web',
  releaseStage: process.env.REAL_NODE_ENV,
  metadata: {
    graphqlUrl: process.env.GRAPHQL_URL,
    graphqlWsUrl: process.env.GRAPHQL_WS_URL,
    googleMapsKey: process.env.GOOGLE_MAPS_KEY,
  },
  plugins: [new BugsnagPluginReact()],
  onError,
};

export { options };
export default (process.env.NODE_ENV !== 'test' && process.env.BUGSNAG_KEY && Bugsnag.start(options)) || null;
