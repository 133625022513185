import { localTtlStorage, getBase64 } from 'utils';

const saveTempForm = async (form) => {
  const tmpForm = Object.assign({}, form);
  tmpForm.imageFiles = [];
  if (form.imageFiles?.length > 0) {
    for (let file of form.imageFiles) {
      if (!file.data) {
        const fileBase64 = await getBase64(file);
        tmpForm.imageFiles.push({ data: fileBase64, name: file.name });
      } else {
        tmpForm.imageFiles.push(file);
      }
    }
  }
  localTtlStorage.setItem('tmpFormOrder', JSON.stringify(tmpForm), 24 * 60 * 60 * 1000);
  return JSON.parse(localTtlStorage.getItem('tmpFormOrder'));
};

export default saveTempForm;
