import { isIOS } from 'react-device-detect';

const isIphoneX = () => {
  const ratio = Math.round(window.devicePixelRatio) || 1;
  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio,
  };
  if (isIOS && screen.width === 1125 && screen.height === 2436) return true;

  return false;
};

export default isIphoneX;
