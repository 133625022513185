import { bugsnag } from 'utils';

const checkInFavoriteTracking = ({ storeName, slug, establishmentName }) => {
  const properties = { 'check in slug': slug };

  if (storeName) properties['check in Store Name'] = storeName;
  if (establishmentName) properties['check in establishmentName'] = establishmentName;

  try {
    window.track({
      mixpanel: {
        name: 'Check In Favorite Card',
        properties,
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export default checkInFavoriteTracking;
