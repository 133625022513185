import { bugsnag } from 'utils';

const trackClickedClaimedBenefit = () => {
  try {
    window.track({
      mixpanel: {
        name: 'Clicked Available Benefits',
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export default trackClickedClaimedBenefit;
