import getEnvironment from '../../utils/functions/getEnvironment';

const title = 'Tracking => Adjust SDK';

const log = (message) => console.log(`%c${message}`, 'font-weight: bold; font-size: 15px;');

const isProduction = getEnvironment() === 'production';

const hasSDK = () => !!window?.AdjustAnalytics?.track || !!window?.webkit?.messageHandlers?.adjustTrack?.postMessage;

const track = (name, token, properties = {}) => {
  window?.AdjustAnalytics?.track?.(JSON.stringify({ name, token, properties })) ||
    window?.webkit?.messageHandlers?.adjustTrack?.postMessage?.({ name, token, properties });

  if (!isProduction) {
    let message = title;
    message += `\nEvent Name:\n- ${name}`;
    message += `\nEvent Token:\n- ${token}`;
    message += `\nProperties (${Object.keys(properties).length}):`;

    for (const property in properties) {
      message += `\n- ${property}: ${properties[property]}`;
    }

    log(message);
  }
};

export default {
  hasSDK,
  track,
};
