import { getDeliveryHour, currencyFormat } from 'utils';

const buildTags = ({ store, client, type, removeReservationTag }) => {
  const tags = [];
  const available = store.opened;
  const offRange = !store.delivery && store.opened;

  if (!offRange && available) {
    tags.push({ text: `${getDeliveryHour(client, type)}`, icon: 'clock' });
    if (store.shippingValue <= 0) {
      tags.push({ text: '<strong>ENTREGA GRÁTIS</strong>' });
    } else {
      tags.push({ text: `Taxa de entrega ${currencyFormat(store.shippingValue)}` });
    }
  }
  if (store?.reservation?.active && !removeReservationTag) {
    tags.push({ text: 'Reserve uma mesa', icon: 'reservation' });
  }

  return tags;
};

export default buildTags;
