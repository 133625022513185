import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { classNames } from 'utils';

import styles from './toast.module.scss';

const Toast = ({ children, animationType, autoDelete, autoDeleteTime }) => {
  const [isFinished, setIsFinished] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const startTouchRef = useRef(null);
  const moved = useRef(false);
  const [swipeAnimation, setSwipeAnimation] = useState('');

  useEffect(() => {
    if (autoDelete) {
      const timer = setTimeout(() => {
        setIsFinished(true);
      }, autoDeleteTime);

      return () => clearTimeout(timer);
    }
  }, [autoDelete, autoDeleteTime]);

  const handleTouchStart = (e) => {
    startTouchRef.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    moved.current = false;
  };

  const handleTouchMove = (e) => {
    if (startTouchRef.current !== null) {
      const deltaX = e.touches[0].clientX - startTouchRef.current.x;
      const deltaY = e.touches[0].clientY - startTouchRef.current.y;
      if (Math.abs(deltaX) > 50 || Math.abs(deltaY) > 50) {
        moved.current = true;
      }
    }
  };

  const handleTouchEnd = (e) => {
    if (moved.current) {
      const endTouch = { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
      const deltaX = endTouch.x - startTouchRef.current.x;
      const deltaY = endTouch.y - startTouchRef.current.y;

      const isFromTop = animationType.includes('top');
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (deltaX > 0) {
          setSwipeAnimation(isFromTop ? 'top-right' : 'bottom-right');
        } else {
          setSwipeAnimation(isFromTop ? 'top-left' : 'bottom-left');
        }
      } else {
        if (deltaY > 0) {
          if (isFromTop) return;
          setSwipeAnimation('bottom');
        } else {
          if (!isFromTop) return;
          setSwipeAnimation('top');
        }
      }

      setIsFinished(true);
    }
  };

  useEffect(() => {
    if (isFinished) {
      const timer = setTimeout(() => {
        setIsMounted(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isFinished]);

  if (!isMounted) {
    return null;
  }

  return createPortal(
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className={classNames(styles.toastContainer, styles[animationType], {
        [styles[`${swipeAnimation ? swipeAnimation : animationType}-finish`]]: isFinished,
      })}
    >
      {children}
    </div>,
    document.getElementById('overlay-root'),
  );
};

Toast.propTypes = {
  children: PropTypes.element.isRequired,
  animationType: PropTypes.string.isRequired,
  autoDelete: PropTypes.bool,
  autoDeleteTime: PropTypes.number,
};

Toast.defaultProps = {
  autoDelete: true,
  autoDeleteTime: 7000,
};

export { Toast };
