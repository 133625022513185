import { useApolloClient } from '@apollo/react-hooks';
import { compressImage } from 'utils';
import { UPLOAD_MULTIPLE_FILES } from 'services/apollo/mutations';

const uploadFiles = async (files) => {
  try {
    const client = useApolloClient();
    if (!files || !client) return [];
    const attachments = [];
    let uploadMultipleFilesIds = [];
    for (const file of files) {
      attachments.push(await compressImage(file, false));
    }

    if (attachments.length > 0) {
      const { data: { uploadMultipleFiles } = {} } = await client.mutate({
        mutation: UPLOAD_MULTIPLE_FILES,
        variables: { files: attachments },
      });
      uploadMultipleFilesIds = uploadMultipleFiles.map((item) => item.id);
    }

    return uploadMultipleFilesIds;
  } catch (e) {
    return [];
  }
};

export default uploadFiles;
