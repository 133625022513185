import getEnvironment from '../../utils/functions/getEnvironment';

const title = 'Tracking => Mixpanel SDK';

const log = (message) => console.log(`%c${message}`, 'font-weight: bold; font-size: 15px;');

const isProduction = getEnvironment() === 'production';

const hasSDK = () =>
  !!window?.MixPanelAnalytics?.track || !!window?.webkit?.messageHandlers?.mixPanelTrack?.postMessage;

const alias = (id) => {
  window?.MixPanelAnalytics?.createAlias?.(id) ||
    window?.webkit?.messageHandlers?.mixPanelCreateAlias?.postMessage?.(id);

  if (!isProduction) {
    let message = title;
    message += `\nAlias User ID:\n- ${id}`;
    log(message);
  }
};

const identify = (id) => {
  window?.MixPanelAnalytics?.identify?.(id) || window?.webkit?.messageHandlers?.mixPanelIdentify?.postMessage?.(id);

  if (!isProduction) {
    let message = title;
    message += `\nIdentify User ID:\n- ${id}`;
    log(message);
  }
};

const track = (name, properties = {}) => {
  window?.MixPanelAnalytics?.track?.(JSON.stringify({ event_name: name, properties })) ||
    window?.webkit?.messageHandlers?.mixPanelTrack?.postMessage?.({ event_name: name, properties });

  if (!isProduction) {
    let message = title;
    message += `\nEvent Name:\n- ${name}`;
    message += `\nProperties (${Object.keys(properties).length}):`;

    for (const property in properties) {
      message += `\n- ${property}: ${properties[property]}`;
    }

    log(message);
  }
};

const register = (properties = {}) => {
  window?.MixPanelAnalytics?.register?.(JSON.stringify(properties)) ||
    window?.webkit?.messageHandlers?.mixPanelRegister?.postMessage?.(properties);

  if (!isProduction) {
    let message = title;
    message += `\nRegister Global Properties`;
    message += `\nProperties (${Object.keys(properties).length}):`;

    for (const property in properties) {
      message += `\n- ${property}: ${properties[property]}`;
    }

    log(message);
  }
};

const peopleSet = (properties = {}) => {
  window?.MixPanelAnalytics?.peopleSet?.(JSON.stringify(properties)) ||
    window?.webkit?.messageHandlers?.mixPanelPeopleSet?.postMessage?.(properties);

  if (!isProduction) {
    let message = title;
    message += `\nRegister People Properties`;
    message += `\nProperties (${Object.keys(properties).length}):`;

    for (const property in properties) {
      message += `\n- ${property}: ${properties[property]}`;
    }

    log(message);
  }
};

const peopleTrackCharge = (amount, properties = {}) => {
  window?.MixPanelAnalytics?.peopleTrackCharge?.(JSON.stringify({ amount, properties })) ||
    window?.webkit?.messageHandlers?.mixPanelPeopleTrackCharge?.postMessage?.({
      amount,
      properties,
    });

  if (!isProduction) {
    let message = title;
    message += `\nTrack People Charge:\n- ${amount}`;
    message += `\nProperties (${Object.keys(properties).length}):`;

    for (const property in properties) {
      message += `\n- ${property}: ${properties[property]}`;
    }

    log(message);
  }
};

const peopleIncrement = (name, value) => {
  window?.MixPanelAnalytics?.peopleIncrement?.(JSON.stringify({ [name]: value })) ||
    window?.webkit?.messageHandlers?.mixPanelPeopleIncrement?.postMessage?.({ [name]: value });

  if (!isProduction) {
    let message = title;
    message += `\nIncrement People Property:\n- ${name}`;
    message += `\nValue:\n- ${value}`;
    log(message);
  }
};

const peopleUnion = (name, values = []) => {
  window?.MixPanelAnalytics?.peopleUnion?.(JSON.stringify({ propertyName: name, values })) ||
    window?.webkit?.messageHandlers?.mixPanelPeopleUnion?.postMessage?.({
      propertyName: name,
      values,
    });

  if (!isProduction) {
    let message = title;
    message += `\nIncrement People List:\n- ${name}`;
    message += `\nValues (${values.length}):`;

    for (const value in values) {
      message += `\n- ${value}`;
    }

    log(message);
  }
};

const reset = () => {
  window?.MixPanelAnalytics?.reset?.() || window?.webkit?.messageHandlers?.mixPanelReset?.postMessage?.({});

  if (!isProduction) {
    let message = title;
    message += `\nReset User`;
    log(message);
  }
};

const unregister = (name) => {
  window?.MixPanelAnalytics?.unregister?.(name) ||
    window?.webkit?.messageHandlers?.mixPanelUnregister?.postMessage?.(name);

  if (!isProduction) {
    let message = title;
    message += `\nUnregister Global Property:\n- ${name}`;
    log(message);
  }
};

export default {
  hasSDK,
  alias,
  identify,
  track,
  register,
  peopleSet,
  peopleTrackCharge,
  peopleIncrement,
  peopleUnion,
  reset,
  unregister,
};
