import React, { useEffect } from 'react';
import { node as reactNode, string } from 'prop-types';
import { createRoot } from 'react-dom/client';

import { Toast } from '../Toast';
import Typography from '../typography';
import Flex from '../flex';

import CheckCircle from 'icons/check-circle-fill.svg';
import OutlineClose from 'icons/outline-close.svg';

import { colors } from 'theme';

import styles from './index.module.scss';

const bodyStatusToast = ({ body }) => {
  const nodeId = `toast-container-${Date.now()}`;
  const node = document.createElement('div');
  node.id = nodeId;
  document.body.appendChild(node);

  const root = createRoot(node);

  const StatusToastComponent = () => {
    useEffect(() => {
      const timer = setTimeout(() => {
        clear();
      }, 7000);
      return () => clearTimeout(timer);
    }, []);

    return (
      <Toast animationType="top" autoDeleteTime={5000}>
        {body}
      </Toast>
    );
  };

  const clear = () => {
    root.unmount();
    node.remove();
  };

  root.render(<StatusToastComponent />);
};

bodyStatusToast.propTypes = {
  body: reactNode.isRequired,
};

const statusToast = ({ type, title, text, action }) => {
  const bgColors = {
    success: colors.green[5],
    error: colors.red[5],
  };

  const StatusContent = () => (
    <Flex borderRadius="10px" gap="16px" align="center" padding="16px 24px" bgColor={bgColors[type]}>
      <div className={styles.iconWrapper}>
        {type === 'success' ? <CheckCircle className={styles.icon} /> : <OutlineClose className={styles.icon} />}
      </div>

      <Flex direction="column" align="flex-start" fullWidth>
        {title && (
          <Typography data-testid="toast-title" color={colors.common.white} variant="body2" className={styles.title}>
            {title}
          </Typography>
        )}
        {text && (
          <Typography data-testid="toast-text" color={colors.common.white} variant="body2">
            {text}
          </Typography>
        )}
      </Flex>
      {action}
    </Flex>
  );

  bodyStatusToast({
    body: <StatusContent />,
  });
};

statusToast.propTypes = {
  title: string.isRequired,
  text: string,
  type: string,
  action: reactNode,
};

statusToast.defaultProps = {
  type: 'success',
  text: '',
  action: null,
};

export { bodyStatusToast, statusToast };
