import { bugsnag } from 'utils';

const shareCheckInTracking = ({ shareObj, isWhatsapp, isCopyLink }) => {
  const properties = { 'Check-in Slug': shareObj.slug, 'Check-in Page Title': shareObj.pageTitle };

  if (shareObj.storeName) properties['Check-in Store Name'] = shareObj.storeName;
  if (shareObj.establishmentName) properties['Check-in Establishment Name'] = shareObj.establishmentName;
  if (isWhatsapp || isCopyLink) properties.Provider = isWhatsapp ? 'whatsapp' : 'copy link';

  try {
    window.track({
      mixpanel: {
        name: 'Share Check-in Modal',
        properties,
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export default shareCheckInTracking;
