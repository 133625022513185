import getPhone from '../getPhone';

const getUser = () => {
  const userString = multiStorage.getItem('user');
  if (!userString) {
    return null;
  }

  const user = JSON.parse(userString);
  if (!user.phoneNumber) {
    user.phoneNumber = getPhone();
  }

  return {
    ...user,
    isBeta: !!user.isBeta,
    dcTerm: !!user.dcTerm,
    gender: user.gender || null,
    birthdate: user.birthdate || null,
    showIntroductionAnything: !!user.showIntroductionAnything,
    privacyPolicy: user.privacyPolicy || null,
    multiplanId: user.multiplanId || null,
  };
};

export default getUser;
