const setItem = (key, value, ttl) => {
  const item = { value };
  if (ttl) {
    item.expiry = new Date().getTime() + ttl;
  }
  return multiStorage.setItem(key, JSON.stringify(item));
};

const getItem = (key, { loadWithoutStructure = true } = {}) => {
  const itemStr = multiStorage.getItem(key);
  if (!itemStr) return null;
  let item = null;
  try {
    item = JSON.parse(itemStr);
  } catch (e) {
    if (!loadWithoutStructure) {
      removeItem(key);
      return null;
    }
  }
  if (item && item.value) {
    if (item.expiry && new Date().getTime() > item.expiry) {
      removeItem(key);
      return null;
    }
    return item.value;
  }
  return itemStr;
};

const removeItem = (key) => multiStorage.removeItem(key);

export default {
  setItem,
  getItem,
  removeItem,
};
