import { bugsnag, setLoading, removeLoading, shoppingsWithPIX } from 'utils';
import { USER, CURRENT_SHOPPING, PARKING_PROMOTIONS } from 'services/apollo/queries';

const openParkingSDK = async ({ client, userVipBenefitId, licensePlate }) => {
  const getData = async () => {
    try {
      setLoading();

      const { user } = client.readQuery({ query: USER });
      const { currentShopping } = client.readQuery({ query: CURRENT_SHOPPING });

      if (!user || !currentShopping) {
        client.writeData({ data: { pathname: '/apps/estacionamento/home?open=sdk' } });
        return false;
      } else {
        const { data: { parkingPromotions } = {} } = await client.query({
          query: PARKING_PROMOTIONS,
          variables: {
            limit: 1,
            shoppingSlug: currentShopping.slug,
            userVipBenefitId,
          },
          fetchPolicy: 'network-only',
        });

        const shoppingsWithMinimumAmount = [];

        const enabledPIX = shoppingsWithPIX.includes(currentShopping.slug);
        const enabledMinimumAmount = shoppingsWithMinimumAmount.includes(currentShopping.slug);

        const data = {
          shoppingSlug: currentShopping.slug,
          enabledPIX,
          id: user.id,
          showAddCoupons: false,
          licensePlate: licensePlate,
        };

        if (parkingPromotions?.length) {
          data.showAddCoupons = true;

          data.promoId = parkingPromotions[0].promoId;
          data.promoUserVipBenefitId = parkingPromotions[0].userVipBenefitId;
          data.promoName = parkingPromotions[0].name;
          data.promoDescription = parkingPromotions[0].description;
          data.promoCover = parkingPromotions[0].cover;
          data.promoEndDate = parkingPromotions[0].endDate;
          data.promoMinimumAmount = enabledMinimumAmount ? 2 : 0;
        }

        try {
          window.track({
            mixpanel: {
              name: 'Parking information loaded',
              properties: {
                'Enabled PIX': data.enabledPIX,
                'License Plate': data.licensePlate,
                'Show Add Coupons': data.showAddCoupons,
                'Promo Id': data.promoId,
                'Promo UserVipBenefitId': data.promoUserVipBenefitId,
                'Promo Name': data.promoName,
                'Promo Description': data.promoDescription,
                'Promo Cover': data.promoCover,
                'Promo End Date': data.promoEndDate,
                'Promo Minimum Amount': data.promoMinimumAmount,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }

        return data;
      }
    } catch (e) {
      try {
        window.track({
          mixpanel: {
            name: 'Error loading parking information',
            properties: {
              Message: e.message,
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }

      bugsnag?.notify(e);
      return { promoError: e };
    } finally {
      removeLoading();
    }
  };
  const dataJSON = await getData();
  if (dataJSON.promoError) return '/showcase/parking-exception-modal';
  if (window.webkit?.messageHandlers?.onParkingTap) {
    if (dataJSON) {
      window.webkit.messageHandlers.onParkingTap.postMessage(dataJSON);
      return !userVipBenefitId ? '/showcase' : '/apps/multivoce/meusbeneficios/used';
    } else return '/signup/phone';
  } else if (window.MultiShoppingAndroid?.onParkingTap) {
    if (dataJSON) {
      window.MultiShoppingAndroid.onParkingTap(JSON.stringify(dataJSON));
      return !userVipBenefitId ? '/showcase' : '/apps/multivoce/meusbeneficios/used';
    } else return '/signup/phone';
  } else {
    if (dataJSON) {
      console.log('DataJSON', dataJSON);
      return '/apps/estacionamento';
    } else return '/signup/phone';
  }
};

export default openParkingSDK;
