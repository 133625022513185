export const colors = {
  ambar: {
    1: '#fff0de',
    3: '#FBB75D',
    4: '#f5a623',
    5: '#ad642b',
    6: '#80431C',
    8: '#602D00',
    9: '#FFC689',
  },
  green: {
    1: '#e5f7f1',
    2: '#99dfc8',
    3: '#4dc69e',
    4: '#00ae75',
    5: '#008258',
    6: '#015c40',
    7: '#1f4c37',
    8: '#1b422e',
    9: '#1e3b32',
  },
  lemon: {
    1: '#f8ffda',
    2: '#dfe880',
    3: '#c0d100',
    4: '#8da400',
    6: '#53671B',
  },
  olive: {
    1: '#ebf8e5',
    2: '#a4d5a9',
    3: '#74ad7f',
    4: '#548560',
    5: '#477c5b',
    6: '#315b48',
    7: '#274c41',
    8: '#477c5b',
    9: '#dbeade',
    10: '#D3EADA',
  },
  turquoise: {
    1: '#1a7f89',
    2: '#8fe2db',
    3: '#7ecbc8',
    4: '#46b4b1',
    5: '#1a7f89',
    6: '#296770',
    9: '#0f515a',
    10: '#041A1D',
  },
  blue: {
    1: '#e8f3ff',
    2: '#b9d9ff',
    4: '#5391f4',
    5: '#2a73e1',
    7: '#0049a7',
    6: '#045ac9',
    8: '#093885',
    9: '#ecf9ff',
  },
  blueTurquoise: {
    1: '#dbfffd',
  },
  purple: {
    1: '#f1e7f7',
    5: '#8364be',
    7: '#6c4caa',
  },
  orange: {
    1: '#ffe8dd',
    2: '#ffb892',
    4: '#ff844c',
    5: '#c8502c',
    6: '#b54928',
    7: '#ed9f5b',
    8: '#792b18',
    9: '#9B361F',
  },
  ocre: {
    1: '#fff0e4',
    2: '#fec598',
    4: '#d67c2d',
    6: '#863f00',
    8: '#612511',
    9: '#AC5600',
  },
  red: {
    1: '#ffe8e2',
    2: '#FEADA4',
    3: '#fa8080',
    5: '#d83d34',
    4: '#f84a4a',
    6: '#c1362f',
    7: '#941411',
    8: '#811a18',
  },
  pink: {
    1: '#cb3d92',
  },
  salmon: {
    5: '#d73a4e',
    8: '#7D1B27',
  },
  gray: {
    1: '#f5f5f5',
    2: '#e7e7e7',
    3: '#c7c7c7',
    4: '#9b9b9b',
    5: '#787575',
    6: '#5d5e5e',
    7: '#4a4a4a',
    8: '#222222',
    9: '#dadada',
    10: '#e5e5e5',
  },
  yellow: {
    1: '#f8f2e6',
    2: '#fde691',
    3: '#e8bf7f',
    4: '#f1bb07',
    5: '#e8bf7f',
    6: '#7f5b34',
    7: '#d3b06c',
    8: '#5f4427',
    9: '#c8a060',
    10: '#e9d8b5',
    11: '#FAF6ED',
    13: '#94712C',
  },
  gold: {
    7: '#4A3916',
  },
  brown: {
    1: '#624B1D',
  },
  common: {
    white: '#ffffff',
    black: '#000000',
  },
};
