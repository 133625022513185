import { CURRENT_SHOPPING, SELECTED_MOVIE_DATE, USER_VIP } from 'services/apollo/queries';
import getSelectedMovieDate from 'services/apollo/helpers/getSelectedMovieDate';

import { bugsnag } from 'utils';

const changeShopping = async ({ client, user, shoppingToGo }) => {
  const { currentShopping } = client.readQuery({ query: CURRENT_SHOPPING });

  await client.writeQuery({ query: CURRENT_SHOPPING, data: { currentShopping: shoppingToGo } });

  if (user && currentShopping && currentShopping.slug !== shoppingToGo.slug) {
    try {
      let currentUserVip;
      try {
        const {
          data: { userVip },
        } = await client.query({
          query: USER_VIP,
          variables: { shoppingSlug: currentShopping.slug },
          fetchPolicy: 'network-only',
        });

        currentUserVip = userVip;
      } catch (e) {
        try {
          window.track({
            mixpanel: {
              name: 'User Vip Error',
              properties: {
                shoppingToGo: shoppingToGo?.slug,
                currentShopping: currentShopping?.slug,
                from: 'Change Shopping',
                userVip: 'Not found',
                error: e?.message,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }
        bugsnag?.notify(e, {
          severity: 'error',
          beforeSend: (report) => {
            const params = {};
            params.shoppingToGo = shoppingToGo?.slug;
            params.currentShopping = currentShopping?.slug;
            params.from = 'Change Shopping';
            params.userVip = 'Not found';
            report.updateMetaData('params', params);
            report.groupingHash = 'UserVip';
          },
        });
      }

      if (currentUserVip?.id) {
        await client.query({
          query: USER_VIP,
          variables: { shoppingSlug: shoppingToGo.slug, upsert: true },
          fetchPolicy: 'network-only',
        });
      }
    } catch (e) {
      try {
        window.track({
          mixpanel: {
            name: 'User Vip Error',
            properties: {
              shoppingToGo: shoppingToGo?.slug,
              currentShopping: currentShopping?.slug,
              from: 'Change Shopping',
              error: e?.message,
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
      bugsnag?.notify(e, {
        severity: 'error',
        beforeSend: (report) => {
          const params = {};
          params.shoppingToGo = shoppingToGo?.slug;
          params.currentShopping = currentShopping?.slug;
          params.from = 'Change Shopping';
          report.updateMetaData('params', params);
          report.groupingHash = 'UserVip';
        },
      });
      throw e;
    }
  }

  multiStorage.setItem('shopping', JSON.stringify(shoppingToGo));
  multiStorage.setItem('lastShopping', shoppingToGo.slug);

  try {
    window.identifyShopping();
  } catch (e) {
    bugsnag?.notify(e);
  }

  await client.writeQuery({
    query: SELECTED_MOVIE_DATE,
    data: { selectedMovieDate: getSelectedMovieDate() },
  });
};

export default changeShopping;
