const shortOrderSelect = `
  id
  orderNumber
  text
  createdAt
  stores {
    id
    name
  }
  amount
  chat {
    id
  }
  agent {
    id
    name
  }
  status {
    id
    name
  }
  user {
    id
    name
  }
  attachments {
    id
    file {
      id
      small
    }
  }
`;

const orderSelect = `
  id
  orderNumber
  text
  stores {
    id
    name
  }
  amount
  createdAt
  chat {
    id
    messages {
      id
      createdAt
      text
      user {
        id
        name
      }
      attachments {
        id
        file {
          id
          small
          medium
          large
        }
      }
    }
  }
  status {
    id
    name
    value
  }
  user {
    id
    name
  }
  agent {
    id
    name
  }
  attachments {
    id
    file {
      id
      small
      medium
      large
    }
  }
`;

const meSelect = `
  id
  cpf
  name
  email
  phoneNumber
  birthdate
  gender
  isBeta
  dcTerm
  sessionToken
  showIntroductionAnything
  privacyPolicy {
    version
  }
  multiplanId
  wifiStatus
  wifiMultiDate
  wifiStartDate
`;

const messageSelect = `
  id
  text
  createdAt
  user {
    id
    name
  }
  attachments {
    id
    file {
      id
      small
    }
  }
`;

const shoppingSelect = `
  id
  name
  slug
  email
  logo {
    id
    original
  }
  cover {
    id
    original
    small
  }
  location {
    latitude
    longitude
  }
  map
  address
  operatingHours
  hasMarketplace
  hasPlayApp
  hasLocker
  hasSlotDelivery
  baseUri
  slotDeliveryHours {
    orderLimitHour
    deliveryLimitHour
  }
  phoneNumbers {
    name
    phoneNumber
  }
  promotionsContacts {
    active
    name
    email
    phoneNumber
    whatsappNumber
  }
  showDirectSelling
  color
  hasAnythingOrder
  footerApps {
    id
    text
    path
    order
    iconFooter{
      id
      icon{
        original
        id
      }
    }
  }
  promotions {
    id
    name
    slug
    benefitGreen
    benefitSilver
    benefitGold
    modalities
    active
  }
  state
  regionCode
`;

const store = `id
name
slug
images
opened
pickup
delivery
deliveryTime
phoneNumbers
mainPhone
hasContract
whatsappNumber
shippingValue
isService
sale {
  active
  text
}
businessHours {
  dayOfWeek
  openAt
  closeAt
}
segments {
  id
  name
}
logo {
  id
  original
}
floors {
  name
  number
  map
}
location {
  token
}
shopping {
  id
  businessHours {
    store {
      start
      end
    }
    restaurant {
      start
      end
    }
  }
}`;

const initialState = {
  orderFilter: 'Por Relevância',
  orderFilterLLV: 'Próximo a expirar',
  segmentsIds: {
    shoppingId: null,
    segmentIds: null,
    gastronomy: null,
    __typename: 'CurrentSegmentIds',
  },
};

export { initialState, meSelect, messageSelect, orderSelect, shoppingSelect, shortOrderSelect, store };
