import query from 'query-string';

const informationsDevice = (location) => {
  let deviceToken = multiStorage.getItem('deviceToken');
  let appIdentifier = multiStorage.getItem('appIdentifier');

  if (
    typeof deviceToken !== 'string' ||
    deviceToken === 'null' ||
    deviceToken === 'undefined' ||
    typeof appIdentifier !== 'string' ||
    appIdentifier === 'null' ||
    appIdentifier === 'undefined'
  ) {
    const arrayInfs = (location && location.search.split('?')) || window.location.href.split('?');
    let queryString = arrayInfs.length > 1 ? arrayInfs[arrayInfs.length - 1] : null;
    if (!queryString) queryString = window.location.hash.replace('#/', '');
    let queryParse = query.parse(queryString);
    if (typeof queryParse === 'object') {
      if (typeof queryParse.deviceToken === 'string') deviceToken = queryParse.deviceToken;
      if (typeof queryParse.appIdentifier === 'string') appIdentifier = queryParse.appIdentifier;
    }
  }

  if (
    (typeof deviceToken === 'string' && deviceToken !== 'null' && deviceToken !== 'undefined') ||
    (typeof appIdentifier === 'string' && appIdentifier !== 'null' && appIdentifier !== 'undefined')
  ) {
    multiStorage.setItem('deviceToken', deviceToken);
    multiStorage.setItem('appIdentifier', appIdentifier);
  } else {
    deviceToken = null;
    appIdentifier = null;
    multiStorage.removeItem('deviceToken');
    multiStorage.removeItem('appIdentifier');
  }
  return { deviceToken, appIdentifier };
};

export default informationsDevice;
