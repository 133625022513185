import Jimp from 'jimp';

const getBase64 = async (file) => {
  const tempFileReader = new FileReader();
  return new Promise((resolve, reject) => {
    tempFileReader.onerror = () => {
      tempFileReader.abort();
      reject(new DOMException('Problem on parsing file.'));
    };

    tempFileReader.onload = () => resolve(tempFileReader.result);
    tempFileReader.readAsDataURL(file);
  });
};

const createFile = async ({ url, name }) => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = {
    type: 'image/jpeg',
  };
  return new File([data], name, metadata);
};

const getArrayBuffer = (file) => {
  const tempFileReader = new FileReader();
  return new Promise((resolve, reject) => {
    tempFileReader.onerror = () => {
      tempFileReader.abort();
      reject(new DOMException('Problem on parsing file.'));
    };

    tempFileReader.onload = () => resolve(tempFileReader.result);
    tempFileReader.readAsArrayBuffer(file);
  });
};

const compressImage = async (file, base64 = true) => {
  if (base64) {
    const fileBase64 = await getBase64(file);
    const jimpImage = await Jimp.read(Buffer.from(fileBase64));
    if (jimpImage.bitmap.width > 1024) jimpImage.resize(1024, Jimp.AUTO);
    return await jimpImage.getBase64Async(Jimp.MIME_JPEG);
  }

  const image = await Jimp.read(await getArrayBuffer(file));
  if (image.bitmap.width > 1024) image.resize(1024, Jimp.AUTO);
  const compressedImage = await image.getBufferAsync(Jimp.MIME_JPEG);
  const blob = new Blob([compressedImage]);
  return new File([blob], 'image.jpg', { type: 'image/jpeg' });
};

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) u8arr[n] = bstr.charCodeAt(n);
  return new File([u8arr], filename, { type: mime });
};

export default compressImage;
export { compressImage, getBase64, dataURLtoFile, getArrayBuffer, createFile };
