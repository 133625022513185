import { formatUTCDate } from 'utils';

export default () => {
  const today = formatUTCDate(new Date());

  return {
    moviesDate: today.toString(),
    movieDate: today.toString(),
    maxDate: null,
    __typename: 'MovieDate',
  };
};
