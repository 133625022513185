import { bugsnag } from 'utils';

const trackFirebaseEvents = (name, properties) => {
  try {
    window.track({
      firebase: {
        name,
        properties,
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export default trackFirebaseEvents;
