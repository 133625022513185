import { bugsnag } from 'utils';

const shareBenefitTracking = ({ shareObj, isWhatsapp, isCopyLink }) => {
  const properties = { 'Benefit id': shareObj.id };

  if (isWhatsapp || isCopyLink) properties.Provider = isWhatsapp ? 'whatsapp' : 'copy link';

  try {
    window.track({
      mixpanel: {
        name: 'Share Benefit Modal',
        properties,
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export default shareBenefitTracking;
