import { handleWhatsapp, bugsnag, goToPromotions } from 'utils';

const goToApp = async ({ link, path = null, currentShopping, user, history, client, isAndroid = false, appName }) => {
  const ticketReservationURI = encodeURI('http://ingresse.com/barracadabra-2021');

  if (!link && !path) {
    bugsnag?.notify(new Error('Go to App without link and path'), {
      severity: 'error',
      beforeSend: (report) => {
        report.updateMetaData('params', {
          typeofLink: typeof link,
          link,
          typeofPath: typeof path,
          path,
        });
        report.groupingHash = 'goToApp';
      },
    });
    history.push('/showcase');
  } else if (path) {
    history.push(path);
  } else {
    const checkInSlash = link.match(/^check-in-(.*)/);
    const checkInBar = link.match(/^check-in\/(.*)/);

    if (link.includes('api.whatsapp.com') || link.includes('wa.me')) {
      try {
        const whatsappNumber = link.match(/(\d+)/)[0];
        const url = new URL(link);
        const searchParams = url.searchParams;
        const whatsappText = searchParams.get('text') || '';
        handleWhatsapp(whatsappNumber, whatsappText);
      } catch (e) {
        bugsnag?.notify(e);
      }
    } else if (link.startsWith('http')) {
      history.push(`/redirect/${encodeURIComponent(link)}/${isAndroid}`);
    } else if (link === 'promocoes') {
      if (!user) {
        history.push('/signup/phone');
      } else {
        const path = await goToPromotions(history, client, appName);
        history.replace(path);
      }
    } else if (link === 'showcase') history.push('/showcase');
    else if (link === 'busca-e-leva') history.push('/order');
    else if (link === 'cupons-presenciais') history.push('/check-ins');
    else if (link === 'venda-direta') history.push('/venda-direta');
    else if (link === 'reserva-em-restaurantes') history.push('/reserva-em-restaurantes');
    else if (link === 'eletroposto') history.push('/apps/estacionamento/home/eletroposto');
    else if (link === 'ingresso-barracadabra') history.push(`/redirect/${ticketReservationURI}/${isAndroid}`);
    else if (link === 'lapis-vermelho') history.push('/vantagens');
    else if (link === 'onde-achar') history.push('/onde-achar');
    else if (link === 'estacionamento') {
      history.push('/apps/estacionamento/home');
    } else if (link === 'wi-fi') {
      history.push('/wifi');
    } else if (checkInSlash) {
      if (checkInSlash[1]) history.push(`/check-in/${checkInSlash[1]}`);
      else history.push('/showcase');
    } else if (checkInBar) {
      if (checkInBar[1]) history.push(`/check-in/${checkInBar[1]}`);
      else history.push('/showcase');
    } else if (link === 'mapa') window.openExternalContent(currentShopping.map);
    else if (link.startsWith('landing')) history.push(`/${link}`);
    else history.push(`/apps/${link}`);
  }
};

export default goToApp;
