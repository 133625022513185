import getEnvironment from '../../utils/functions/getEnvironment';

const title = 'Tracking => Google Tag Manager';

const log = (message) => console.log(`%c${message}`, 'font-weight: bold; font-size: 15px;');

const isProduction = getEnvironment() === 'production';

const track = (event, properties = {}) => {
  const dataLayer = window?.dataLayer || [];

  dataLayer.push({
    event,
    ...properties,
  });

  if (!isProduction) {
    let message = title;
    message += `\nTrigger Name:\n- ${event}`;
    message += `\nProperties (${Object.keys(properties).length}):`;

    for (const property in properties) {
      message += `\n- ${property}: ${properties[property]}`;
    }

    log(message);
  }
};

export default {
  track,
};
