import getEnvironment from '../../utils/functions/getEnvironment';

const title = 'Tracking => Firebase SDK';

const log = (message) => console.log(`%c${message}`, 'font-weight: bold; font-size: 15px;');

const isProduction = getEnvironment() === 'production';

const track = (name, parameters = {}) => {
  window?.webkit?.messageHandlers?.firebase?.postMessage?.({
    command: 'logEvent',
    name,
    parameters,
  }) || window?.MultiShoppingAndroid?.logEvent?.(name, JSON.stringify(parameters));

  if (!isProduction) {
    let message = title;
    message += `\nEvent Name:\n- ${name}`;
    message += `\nProperties (${Object.keys(parameters).length}):`;

    for (const parameter in parameters) {
      message += `\n- ${parameter}: ${parameters[parameter]}`;
    }

    log(message);
  }
};

const setUserProperty = (name, value) =>
  window?.webkit?.messageHandlers?.firebase?.postMessage?.({
    command: 'setUserProperty',
    name,
    parameters: value,
  }) || window?.MultiShoppingAndroid?.setUserProperty?.(name, value);

export default {
  track,
  setUserProperty,
};
