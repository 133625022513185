import { bugsnag } from 'utils';

const trackClickedUseBenefit = ({
  benefitType,
  benefitID,
  benefitName,
  expirationDate,
  redeemDate,
  promotionID,
  benefitStore,
}) => {
  try {
    window.track({
      mixpanel: {
        name: 'Clicked use Benefit',
        properties: {
          'Benefit Type': benefitType,
          'Benefit ID': benefitID,
          'Benefit Name': benefitName,
          'Benefit Expiration Date': expirationDate,
          'Benefit Redeem Date': redeemDate,
          'Promotion ID': promotionID,
          'Benefit Store': benefitStore || undefined,
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export default trackClickedUseBenefit;
