import { bugsnag, getEnvironment, informationsDevice, maskUserName, maskUserCpf } from 'utils';
import { getUser, getPhone, getShopping } from 'services/storage';
import firebase from '../firebase';
import mixpanel from '../mixpanel';
import googleTagManager from '../google-tag-manager';
import adjust from '../adjust';
import salesForce from '../salesForce';

(function tracking(window) {
  window.alias = (config) => {
    if (config?.mixpanel) {
      mixpanel.alias(config.mixpanel.id);
    }

    if (mixpanel.hasSDK() && config?.googleTagManager?.globalWithoutMixpanel) {
      googleTagManager.track(
        config.googleTagManager.globalWithoutMixpanel.name,
        config.googleTagManager.globalWithoutMixpanel.properties,
      );
    } else if (!mixpanel.hasSDK() && config?.googleTagManager?.global) {
      googleTagManager.track(config.googleTagManager.global.name, config.googleTagManager.global.properties);
    }
  };

  window.identify = (config) => {
    if (config?.mixpanel) {
      mixpanel.identify(config.mixpanel.id);
    }

    if (config?.salesForce) {
      salesForce.identify(config.salesForce.profileId);
    }

    if (mixpanel.hasSDK() && config?.googleTagManager?.globalWithoutMixpanel) {
      googleTagManager.track(
        config.googleTagManager.globalWithoutMixpanel.name,
        config.googleTagManager.globalWithoutMixpanel.properties,
      );
    } else if (!mixpanel.hasSDK() && config?.googleTagManager?.global) {
      googleTagManager.track(config.googleTagManager.global.name, config.googleTagManager.global.properties);
    }
  };

  window.track = (config) => {
    if (config?.firebase) {
      firebase.track(config.firebase.name, config.firebase.properties);
    }

    if (config?.mixpanel) {
      mixpanel.track(config.mixpanel.name, config.mixpanel.properties);
    }

    if (config?.salesForce) {
      salesForce.track(config.salesForce.name, config.salesForce.properties);
    }

    if (mixpanel.hasSDK() && config?.googleTagManager?.globalWithoutMixpanel) {
      googleTagManager.track(
        config.googleTagManager.globalWithoutMixpanel.name,
        config.googleTagManager.globalWithoutMixpanel.properties,
      );
    } else if (!mixpanel.hasSDK() && config?.googleTagManager?.global) {
      googleTagManager.track(config.googleTagManager.global.name, config.googleTagManager.global.properties);
    }

    if (adjust.hasSDK() && config?.adjust) {
      adjust.track(config.adjust.name, config.adjust.token, config.adjust.properties);
    }
  };

  window.register = (config) => {
    if (config?.mixpanel) {
      mixpanel.register(config.mixpanel.properties);
    }

    if (mixpanel.hasSDK() && config?.googleTagManager?.globalWithoutMixpanel) {
      googleTagManager.track(
        config.googleTagManager.globalWithoutMixpanel.name,
        config.googleTagManager.globalWithoutMixpanel.properties,
      );
    } else if (!mixpanel.hasSDK() && config?.googleTagManager?.global) {
      googleTagManager.track(config.googleTagManager.global.name, config.googleTagManager.global.properties);
    }
  };

  window.peopleSet = (config) => {
    if (config?.mixpanel) {
      mixpanel.peopleSet(config.mixpanel.properties);
    }

    if (config?.salesForce) {
      salesForce.peopleSet(config.salesForce.properties);
    }

    if (mixpanel.hasSDK() && config?.googleTagManager?.globalWithoutMixpanel) {
      googleTagManager.track(
        config.googleTagManager.globalWithoutMixpanel.name,
        config.googleTagManager.globalWithoutMixpanel.properties,
      );
    } else if (!mixpanel.hasSDK() && config?.googleTagManager?.global) {
      googleTagManager.track(config.googleTagManager.global.name, config.googleTagManager.global.properties);
    }
  };

  window.peopleTrackCharge = (config) => {
    if (config?.mixpanel) {
      mixpanel.peopleTrackCharge(config.mixpanel.amount, config.mixpanel.properties);
    }

    if (mixpanel.hasSDK() && config?.googleTagManager?.globalWithoutMixpanel) {
      googleTagManager.track(
        config.googleTagManager.globalWithoutMixpanel.name,
        config.googleTagManager.globalWithoutMixpanel.properties,
      );
    } else if (!mixpanel.hasSDK() && config?.googleTagManager?.global) {
      googleTagManager.track(config.googleTagManager.global.name, config.googleTagManager.global.properties);
    }
  };

  window.peopleIncrement = (config) => {
    if (config?.mixpanel) {
      mixpanel.peopleIncrement(config.mixpanel.name, config.mixpanel.value);
    }

    if (mixpanel.hasSDK() && config?.googleTagManager?.globalWithoutMixpanel) {
      googleTagManager.track(
        config.googleTagManager.globalWithoutMixpanel.name,
        config.googleTagManager.globalWithoutMixpanel.properties,
      );
    } else if (!mixpanel.hasSDK() && config?.googleTagManager?.global) {
      googleTagManager.track(config.googleTagManager.global.name, config.googleTagManager.global.properties);
    }
  };

  window.peopleUnion = (config) => {
    if (config?.mixpanel) {
      mixpanel.peopleUnion(config.mixpanel.name, config.mixpanel.values);
    }

    if (mixpanel.hasSDK() && config?.googleTagManager?.globalWithoutMixpanel) {
      googleTagManager.track(
        config.googleTagManager.globalWithoutMixpanel.name,
        config.googleTagManager.globalWithoutMixpanel.properties,
      );
    } else if (!mixpanel.hasSDK() && config?.googleTagManager?.global) {
      googleTagManager.track(config.googleTagManager.global.name, config.googleTagManager.global.properties);
    }
  };

  window.reset = (config) => {
    mixpanel.reset();

    if (mixpanel.hasSDK() && config?.googleTagManager?.globalWithoutMixpanel) {
      googleTagManager.track(
        config.googleTagManager.globalWithoutMixpanel.name,
        config.googleTagManager.globalWithoutMixpanel.properties,
      );
    } else if (!mixpanel.hasSDK() && config?.googleTagManager?.global) {
      googleTagManager.track(config.googleTagManager.global.name, config.googleTagManager.global.properties);
    }
  };

  window.unregister = (config) => {
    if (config?.mixpanel) {
      mixpanel.unregister(config.mixpanel.name);
    }

    if (mixpanel.hasSDK() && config?.googleTagManager?.globalWithoutMixpanel) {
      googleTagManager.track(
        config.googleTagManager.globalWithoutMixpanel.name,
        config.googleTagManager.globalWithoutMixpanel.properties,
      );
    } else if (!mixpanel.hasSDK() && config?.googleTagManager?.global) {
      googleTagManager.track(config.googleTagManager.global.name, config.googleTagManager.global.properties);
    }
  };

  window.setUserProperty = firebase.setUserProperty;

  window.identifyUser = () => {
    const user = getUser();
    if (!user) {
      return;
    }

    window.identify({
      mixpanel: {
        id: user.id,
      },
      salesForce: {
        profileId: user.multiplanId,
      },
    });

    const userPhone = getPhone();

    if (userPhone) {
      window.peopleSet({
        mixpanel: {
          properties: {
            $phone: userPhone,
          },
        },
      });
    }

    window.peopleSet({
      mixpanel: {
        properties: {
          ID: user.id,
          CPF: maskUserCpf(user.cpf),
          $name: maskUserName(user.name),
          $email: user.email,
          'Is Beta': user.isBeta,
          'DC Terms': user.dcTerm,
          'Multiplan ID': user.multiplanId,
        },
      },
      googleTagManager: {
        global: {
          name: 'identifyUser',
          properties: {
            userPhone,
            userId: user.id,
            userName: maskUserName(user.name),
            userEmail: user.email,
            userCpf: maskUserCpf(user.cpf),
            userDcTerms: user.dcTerm,
            userIsBeta: user.isBeta,
          },
        },
        globalWithoutMixpanel: {
          name: 'identifyUserWithoutMixpanel',
          properties: {
            userPhone,
            userId: user.id,
            userName: maskUserName(user.name),
            userEmail: user.email,
            userCpf: maskUserCpf(user.cpf),
            userDcTerms: user.dcTerm,
            userIsBeta: user.isBeta,
          },
        },
      },
      salesForce: {
        properties: {
          FirstName: maskUserName(user.name),
          Email: user.email,
          Phone: userPhone ? userPhone : '',
        },
      },
    });
  };

  window.identifyShopping = () => {
    const shopping = getShopping();
    if (!shopping) {
      return;
    }

    window.register({
      mixpanel: {
        properties: {
          'Shopping ID': shopping.id,
          'Shopping Name': shopping.name,
        },
      },
    });

    window.peopleUnion({
      mixpanel: {
        name: 'Visited Shoppings IDs',
        values: [shopping.id],
      },
    });

    window.peopleUnion({
      mixpanel: {
        name: 'Visited Shoppings Names',
        values: [shopping.name],
      },
    });

    window.peopleSet({
      mixpanel: {
        properties: {
          'Last Visited Shopping ID': shopping.id,
          'Last Visited Shopping Name': shopping.name,
        },
      },
      googleTagManager: {
        global: {
          name: 'identifyShopping',
          properties: {
            shoppingId: shopping.id,
            shoppingName: shopping.name,
          },
        },
      },
    });
  };

  window.identifyDeviceToken = () => {
    const { deviceToken } = informationsDevice();

    window.register({
      mixpanel: {
        properties: {
          'Device Token': deviceToken || undefined,
        },
      },
    });

    window.peopleSet({
      mixpanel: {
        properties: {
          'Device Token': deviceToken || undefined,
        },
      },
      googleTagManager: {
        global: {
          name: 'identifyDeviceToken',
          properties: {
            deviceToken: deviceToken || undefined,
          },
        },
      },
    });
  };

  window.identifyEnvironment = () => {
    const environment = getEnvironment();

    if (!environment) {
      return;
    }

    window.register({
      mixpanel: {
        properties: {
          Environment: environment,
        },
      },
    });

    window.peopleSet({
      mixpanel: {
        properties: {
          Environment: environment,
        },
      },
      googleTagManager: {
        global: {
          name: 'identifyEnvironment',
          properties: {
            environment,
          },
        },
      },
    });
  };

  window.identifyWebVersion = () => {
    window.register({
      mixpanel: {
        properties: {
          'Web Version': VERSION,
        },
      },
    });

    window.peopleSet({
      mixpanel: {
        properties: {
          'Web Version': VERSION,
        },
      },
      googleTagManager: {
        global: {
          name: 'identifyWebVersion',
          properties: {
            webVersion: VERSION,
          },
        },
      },
    });
  };

  try {
    window.identifyDeviceToken();
  } catch (e) {
    bugsnag?.notify(e);
  }

  try {
    window.identifyEnvironment();
  } catch (e) {
    bugsnag?.notify(e);
  }

  try {
    window.identifyShopping();
  } catch (e) {
    bugsnag?.notify(e);
  }

  try {
    window.identifyWebVersion();
  } catch (e) {
    bugsnag?.notify(e);
  }

  if (!multiStorage.getItem('isForceRefreshing')) {
    try {
      window.track({
        firebase: {
          name: 'started_splash_screen',
        },
        mixpanel: {
          name: 'Started Splash Screen',
        },
        googleTagManager: {
          global: {
            name: 'startedSplashScreen',
          },
        },
      });
    } catch (e) {
      bugsnag?.notify(e);
    }
  }
})(window);
