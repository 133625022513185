const marketFreePassShoppings = [
  'barrashoppingsul',
  'bh-shopping',
  'parkjacarepagua',
  'parkshopping-canoas',
  'parkshoppingbarigui',
  'parkshoppingcampogrande',
  'ribeiraoshopping',
  'shopping-analia-franco',
];

const gymFreePassShoppings = [
  'barrashopping',
  'bh-shopping',
  'jundiaishopping',
  'parkjacarepagua',
  'parkshopping',
  'parkshopping-canoas',
  'parkshoppingbarigui',
  'parkshoppingcampogrande',
  'ribeiraoshopping',
  'shopping-analia-franco',
  'shoppingsantaursula',
];

const shoppingsWithoutTicketGate = [
  'morumbishopping',
  'jundiaishopping',
  'parkshopping',
  'ribeiraoshopping',
  'barrashoppingsul',
  'parkshopping-canoas',
  'parkshoppingbarigui',
];

const virtualTicketShoppings = [
  'barrashoppingsul',
  'bh-shopping',
  'parkjacarepagua',
  'ribeiraoshopping',
  'shopping-analia-franco',
  'parkshopping-canoas',
  'parkshoppingbarigui',
  'parkshoppingcampogrande',
];

const ITFBarcodeShoppings = ['parkshopping-canoas', 'parkshoppingbarigui', 'parkshoppingcampogrande'];

const EANBarcodeShoppings = [
  'barrashoppingsul',
  'bh-shopping',
  'parkjacarepagua',
  'ribeiraoshopping',
  'shopping-analia-franco',
];

const shoppingsWithPIX = [
  'shopping-analia-franco',
  'morumbishopping',
  'parkshoppingcampogrande',
  'patio-savassi',
  'parkshopping-canoas',
  'diamondmall',
  'parkshopping',
  'parkshoppingbarigui',
  'barrashoppingsul',
  'bh-shopping',
  'jundiaishopping',
  'parkshoppingsaocaetano',
  'shoppingvilaolimpia',
  'ribeiraoshopping',
  'barrashopping',
  'villagemall',
  'shoppingsantaursula',
  'parkjacarepagua',
  'parque-shopping-maceio',
];

const shoppingSlugToName = new Map([
  ['barrashopping', 'BarraShopping'],
  ['barrashoppingsul', 'BarraShoppingSul'],
  ['bh-shopping', 'BH Shopping'],
  ['diamondmall', 'DiamondMall'],
  ['jundiaishopping', 'JundiaíShopping'],
  ['morumbishopping', 'MorumbiShopping'],
  ['parque-shopping-maceio', 'Parque Shopping Maceió'],
  ['parkjacarepagua', 'ParkJacarepaguá'],
  ['parkshopping-canoas', 'ParkShopping Canoas'],
  ['parkshopping', 'ParkShopping'],
  ['parkshoppingbarigui', 'ParkShoppingBarigüi'],
  ['parkshoppingcampogrande', 'ParkShoppingCampoGrande'],
  ['parkshoppingsaocaetano', 'ParkShoppingSãoCaetano'],
  ['patio-savassi', 'Pátio Savassi'],
  ['ribeiraoshopping', 'RibeirãoShopping'],
  ['shopping-analia-franco', 'Shopping Anália Franco'],
  ['shoppingsantaursula', 'ShoppingSantaÚrsula'],
  ['shoppingvilaolimpia', 'ShoppingVilaOlímpia'],
  ['villagemall', 'VillageMall'],
]);

const shoppingsSdkMobits = [
  'barrashopping',
  'parkjacarepagua',
  'parkshoppingcampogrande',
  'villagemall',
  'parkshopping',
];

const shoppingLocations = {
  'São Paulo': [
    'MorumbiShopping',
    'ShoppingVilaOlímpia',
    'ShoppingAnáliaFranco',
    'ParkShoppingSãoCaetano',
    'JundiaíShopping',
    'RibeirãoShopping',
    'ShoppingSantaÚrsula',
  ],
  'Minas Gerais': ['BH Shopping', 'DiamondMall', 'PátioSavassi'],
  'Distrito Federal': ['ParkShopping'],
  'Rio de Janeiro': ['ParkJacarepaguá', 'BarraShopping e NewYorkCityCenter', 'VillageMall', 'ParkShoppingCampoGrande'],
  'Rio Grande do Sul': ['BarraShoppingSul', 'ParkShoppingCanoas'],
  Paraná: ['ParkShoppingBarigüi'],
};

const shoppingWithMvcDiscount = [
  'parkshopping',
  'barrashopping',
  'parkjacarepagua',
  'parkshoppingcampogrande',
  'villagemall',
  'morumbishopping',
  'shoppingvilaolimpia',
  'shopping-analia-franco',
  'parkshoppingsaocaetano',
  'jundiaishopping',
  'ribeiraoshopping',
  'shoppingsantaursula',
  'barrashoppingsul',
  'parkshopping-canoas',
  'parkshoppingbarigui',
  'diamondmall',
  'bh-shopping',
  'patio-savassi',
];

export {
  marketFreePassShoppings,
  gymFreePassShoppings,
  shoppingsWithoutTicketGate,
  virtualTicketShoppings,
  ITFBarcodeShoppings,
  EANBarcodeShoppings,
  shoppingsWithPIX,
  shoppingsSdkMobits,
  shoppingSlugToName,
  shoppingLocations,
  shoppingWithMvcDiscount,
};
