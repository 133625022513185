import { SELECTED_ADDRESS, CURRENT_LOCATION, CURRENT_SHOPPING } from 'services/apollo/queries';

export default (client) => {
  const { selectedAddress } = client.readQuery({ query: SELECTED_ADDRESS });
  if (selectedAddress)
    return {
      latitude: selectedAddress.location.latitude,
      longitude: selectedAddress.location.longitude,
    };

  const { currentLocation } = client.readQuery({ query: CURRENT_LOCATION });
  if (currentLocation && currentLocation.latitude && currentLocation.longitude)
    return {
      latitude: currentLocation.latitude,
      longitude: currentLocation.longitude,
    };

  const { currentShopping } = client.readQuery({ query: CURRENT_SHOPPING });
  if (currentShopping && currentShopping.location)
    return {
      latitude: currentShopping.location.latitude,
      longitude: currentShopping.location.longitude,
    };

  return null;
};
