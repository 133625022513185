import { saveTempForm, localTtlStorage } from 'utils';

const updateTempForm = async (newProperty) => {
  const tmpForm = JSON.parse(localTtlStorage.getItem('tmpFormOrder', { loadWithoutStructure: false })) || {};
  return await saveTempForm({
    ...tmpForm,
    ...newProperty,
  });
};

export default updateTempForm;
