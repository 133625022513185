import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { colors } from 'theme';

const Typography = ({
  variant = 'body1',
  color = colors.gray[5],
  children,
  error,
  className,
  mt,
  mb,
  align,
  bold = false,
  weight,
  as: ComponentType = 'p',
  style: styleProp,
  ...props
}) => {
  const style = {
    marginTop: mt,
    marginBottom: mb,
    textAlign: align,
    ...styleProp,
  };

  return (
    <ComponentType
      className={classnames(
        styles[variant],
        styles[`weight-${weight}`],
        { [styles.error]: error, [styles.bold]: bold },
        className,
      )}
      style={{
        color: color,
        ...style,
      }}
      {...props}
    >
      {children}
    </ComponentType>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'subtitle', 'body1', 'body2', 'body3', 'error', 'caption']).isRequired,
  color: PropTypes.string,
  weight: PropTypes.oneOf(['regular', 'medium', 'bold']),
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
  className: PropTypes.string,
  mt: PropTypes.string,
  mb: PropTypes.string,
  align: PropTypes.oneOf(['center', 'left', 'right']),
  bold: PropTypes.bool,
  as: PropTypes.elementType,
  style: PropTypes.object,
};

Typography.defaultProps = {
  variant: 'body1',
  color: '#787575',
  error: false,
  className: null,
  mt: '',
  mb: '',
  weight: '',
  align: 'inherit',
  as: 'p',
  bold: false,
  style: {},
};

export default Typography;
