import { bugsnag } from 'utils';

const shareEventTracking = ({ shareObj, isWhatsapp, isCopyLink }) => {
  const properties = { 'Event Slug': shareObj.slug };

  if (shareObj.eventTitle) properties['Event Name'] = shareObj.eventTitle;
  if (isWhatsapp || isCopyLink) properties.Provider = isWhatsapp ? 'whatsapp' : 'copy link';

  try {
    window.track({
      mixpanel: {
        name: 'Share Event Modal',
        properties,
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export default shareEventTracking;
