const shoppings = [
  {
    suffix: 'barrashopping',
    name: 'BarraShopping',
    id: 'sTrUiDFefD',
    baseUri: 'https://www.barrashopping.com.br',
  },
  {
    suffix: 'parkshoppingcampogrande',
    name: 'ParkShoppingCampoGrande',
    id: 'Rlxzo9990n',
    baseUri: 'https://www.parkshoppingcampogrande.com.br',
  },
  {
    suffix: 'villagemall',
    name: 'VillageMall',
    id: 'mavtGolgOe',
    baseUri: 'https://www.shoppingvillagemall.com.br',
  },
  {
    suffix: 'jundiaishopping',
    name: 'JundiaíShopping',
    id: 'MrlVKFR5OP',
    baseUri: 'https://www.jundiaishopping.com.br',
  },
  {
    suffix: 'morumbishopping',
    name: 'MorumbiShopping',
    id: 'C2OadsoDyg',
    baseUri: 'https://www.morumbishopping.com.br',
  },
  {
    suffix: 'parkshoppingsaocaetano',
    name: 'ParkShoppingSãoCaetano',
    id: 'jrPAb3Knam',
    baseUri: 'https://www.parkshoppingsaocaetano.com.br',
  },
  {
    suffix: 'ribeiraoshopping',
    name: 'RibeirãoShopping',
    id: '3aLo2EQpHR',
    baseUri: 'https://www.ribeiraoshopping.com.br',
  },
  {
    suffix: 'shopping-analia-franco',
    name: 'Shopping Anália Franco',
    id: 'CZLAWA2ppO',
    baseUri: 'https://www.shoppinganaliafranco.com.br',
  },
  {
    suffix: 'shoppingsantaursula',
    name: 'ShoppingSantaÚrsula',
    id: '0mAy7uKFyZ',
    baseUri: 'https://www.shoppingsantaursula.com.br',
  },
  {
    suffix: 'shoppingvilaolimpia',
    name: 'ShoppingVilaOlímpia',
    id: 'Pkln9uC3PO',
    baseUri: 'https://www.shoppingvilaolimpia.com.br',
  },
  {
    suffix: 'bh-shopping',
    name: 'BH Shopping',
    id: '0cJRklbIZi',
    baseUri: 'https://www.bhshopping.com.br',
  },
  {
    suffix: 'diamondmall',
    name: 'DiamondMall',
    id: 'CC5ChvABMt',
    baseUri: 'https://www.diamondmall.com.br',
  },
  {
    suffix: 'patio-savassi',
    name: 'Pátio Savassi',
    id: 'boOBYYE73Q',
    baseUri: 'https://www.patiosavassi.com',
  },
  {
    suffix: 'barrashoppingsul',
    name: 'BarraShoppingSul',
    id: '6FB5Vr1CJL',
    baseUri: 'https://www.barrashoppingsul.com.br',
  },
  {
    suffix: 'parkshopping-canoas',
    name: 'ParkShopping Canoas',
    id: 'Iq0u1W47Xg',
    baseUri: 'https://www.parkshoppingcanoas.com.br',
  },
  {
    suffix: 'parkshoppingbarigui',
    name: 'ParkShoppingBarigüi',
    id: 'wifDAPQsK8',
    baseUri: 'https://www.parkshoppingbarigui.com.br',
  },
  {
    suffix: 'parkshopping',
    name: 'ParkShopping',
    id: 'CQoOT3t1rr',
    baseUri: 'https://www.parkshopping.com.br',
  },
  {
    suffix: 'parque-shopping-maceio',
    name: 'Parque Shopping Maceió',
    id: 'CH1A2odpRh',
    baseUri: '',
  },
];

const shoppingByUrl = () => {
  for (const shopping of shoppings) {
    if (window.location.href.indexOf(shopping.suffix) > -1) {
      return {
        id: shopping.id,
        name: shopping.name,
      };
    }
  }

  return null;
};

export default shoppingByUrl;
