import { bugsnag, setLoading, removeLoading } from 'utils';
import { USER, CURRENT_SHOPPING } from 'services/apollo/queries';
import { SAVE_USER_VIEW_TOKEN } from 'services/apollo/mutations';

const goToWifi = async (client) => {
  const getToken = async () => {
    try {
      setLoading();
      const { user } = client.readQuery({ query: USER });
      const { currentShopping } = client.readQuery({ query: CURRENT_SHOPPING });
      if (!user || !currentShopping) {
        client.writeData({ data: { pathname: 'wifi' } });
        return false;
      } else {
        const { data: { saveUserViewToken } = {} } = await client.mutate({
          mutation: SAVE_USER_VIEW_TOKEN,
          variables: { partnerSlug: 'linktel' },
        });

        try {
          window.track({
            mixpanel: {
              name: 'Wifi information loaded',
              properties: {
                Token: saveUserViewToken?.token,
              },
            },
          });
        } catch (e) {
          bugsnag?.notify(e);
        }

        return saveUserViewToken?.token;
      }
    } catch (e) {
      try {
        window.track({
          mixpanel: {
            name: 'Error loading Wifi information',
            properties: {
              Message: e.message,
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }

      bugsnag?.notify(e);
    } finally {
      removeLoading();
    }
  };

  const token = await getToken();

  if (window.webkit?.messageHandlers?.onWifiTap) {
    if (token) {
      window.webkit.messageHandlers.onWifiTap.postMessage(token);
      return '/showcase';
    } else return '/signup/phone';
  } else if (window.MultiShoppingAndroid?.onWifiTap) {
    if (token) {
      window.MultiShoppingAndroid.onWifiTap(JSON.stringify(token));
      return '/showcase';
    } else return '/signup/phone';
  } else {
    if (token) {
      return '/wifi';
    } else return '/signup/phone';
  }
};

export default goToWifi;
