import { getLocation, getNearestShopping, bugsnag } from 'utils';

import { CURRENT_SHOPPING, CURRENT_LOCATION } from 'services/apollo/queries';

const goToShoppingsAndSetLocation = async (client, history, active) => {
  let currentLocation = null;
  let shopping = null;
  if (active) {
    try {
      const location = await getLocation();
      currentLocation = {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        skip: false,
        accept: true,
        __typename: 'CurrentLocation',
      };
      shopping = await getNearestShopping(client, currentLocation);
    } catch (e) {
      const data = client.readQuery({ query: CURRENT_LOCATION });
      currentLocation = data.currentLocation;
      currentLocation.accept = false;
      currentLocation.skip = (e && e.skip) || false;

      const errorText = JSON.stringify(e);
      if (errorText && errorText !== 'null' && errorText !== '{}' && errorText !== '{"skip":true}')
        bugsnag?.notify(new Error(`Get Location: ${errorText}`), (event) => {
          event.severity = 'error';
          event.groupingHash = 'goToShoppingsAndSetLocation';
        });
    }
  } else {
    currentLocation = {
      latitude: null,
      longitude: null,
      accept: false,
      skip: true,
      __typename: 'CurrentLocation',
    };
  }

  client.writeQuery({ query: CURRENT_LOCATION, data: { currentLocation } });
  multiStorage.setItem('location', JSON.stringify(currentLocation));
  if (shopping) {
    client.writeQuery({ query: CURRENT_SHOPPING, data: { currentShopping: shopping } });
    multiStorage.setItem('shopping', JSON.stringify(shopping));
    try {
      window.identifyShopping();
    } catch (e) {
      bugsnag?.notify(e, (event) => {
        event.severity = 'error';
        event.groupingHash = 'goToShoppingsAndSetLocation';
      });
    }
    history.push({
      pathname: 'shoppings',
      state: { from: 'home' },
    });
  } else {
    history.push({
      pathname: 'shoppings',
      state: { from: 'home' },
    });
  }
};

export default goToShoppingsAndSetLocation;
