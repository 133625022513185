import { shoppingByUrl } from 'utils';

const trackClickedStoreModalContact = ({ isWeb, type, option }) => {
  const data = {
    mixpanel: {
      name: 'Clicked Store Modal Contact',
      properties: {
        'Is Web': isWeb,
        'Contact Type': type,
        'Contact Option': option,
      },
    },
    googleTagManager: {
      global: {
        name: 'clickedStoreModalContact',
        properties: {
          isWeb,
          contactType: type,
          contactOption: option,
        },
      },
    },
  };

  if (isWeb) {
    const shopping = shoppingByUrl();
    if (shopping) {
      data.mixpanel.properties['Shopping ID'] = shopping.id;
      data.mixpanel.properties['Shopping Name'] = shopping.name;
      data.googleTagManager.global.properties.shoppingId = shopping.id;
      data.googleTagManager.global.properties.shoppingName = shopping.name;
    }
  }

  window.track(data);
};

export default trackClickedStoreModalContact;
