import { shoppingByUrl } from 'utils';

const trackClickedStoreCard = ({ id, name, slug, typename, isWeb, shoppingId, shoppingName }) => {
  const storeType = typename === 'Store' ? 'goods' : 'foods';
  const data = {
    mixpanel: {
      name: 'Clicked Store Card',
      properties: {
        'Store ID': id,
        'Store Name': name,
        'Store Slug': slug,
        'Store Type': storeType,
        'Is Web': isWeb,
        'Shopping ID': shoppingId,
        'Shopping Name': shoppingName,
      },
    },
    googleTagManager: {
      global: {
        name: 'clickedStoreCard',
        properties: {
          storeId: id,
          storeName: name,
          storeSlug: slug,
          storeType: storeType,
          isWeb,
          shoppingId,
          shoppingName,
        },
      },
      globalWithoutMixpanel: {
        name: 'clickedStoreCardWithoutMixpanel',
        properties: {
          storeId: id,
          storeName: name,
          storeSlug: slug,
          storeType: storeType,
          isWeb,
          shoppingId,
          shoppingName,
        },
      },
      adjust: {
        name: 'clickedStoreCardIdentifier',
        token: '8luukm',
      },
    },
  };

  if (isWeb) {
    const shopping = shoppingByUrl();
    if (shopping) {
      data.mixpanel.properties['Shopping ID'] = shopping.id;
      data.mixpanel.properties['Shopping Name'] = shopping.name;
      data.googleTagManager.global.properties.shoppingId = shopping.id;
      data.googleTagManager.global.properties.shoppingName = shopping.name;
    }
  }

  window.track(data);
};

export default trackClickedStoreCard;
