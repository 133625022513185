import { USER, CURRENT_SHOPPING, SUMMARY, USER_VIP } from 'services/apollo/queries';
import { phoneNumberMask, getDateDay, getDateMonth, getDateYear, isDeviceUpdated } from 'utils';
import { parsePhoneNumber } from 'libphonenumber-js';
import { PROMOTION_LPR_REGISTRATIONS } from './queries';

const smartPromoShoppings = new Map([
  ['bh-shopping', process.env.SMARTPROMO_CAMPAIGN_ID_BHS],
  ['barrashopping', process.env.SMARTPROMO_CAMPAIGN_ID_BRS],
  ['barrashoppingsul', process.env.SMARTPROMO_CAMPAIGN_ID_BSS],
  ['diamondmall', process.env.SMARTPROMO_CAMPAIGN_ID_DMM],
  ['jundiaishopping', process.env.SMARTPROMO_CAMPAIGN_ID_JDS],
  ['morumbishopping', process.env.SMARTPROMO_CAMPAIGN_ID_MBS],
  ['parkshoppingcampogrande', process.env.SMARTPROMO_CAMPAIGN_ID_PCG],
  ['parkshopping-canoas', process.env.SMARTPROMO_CAMPAIGN_ID_PCN],
  ['parkshoppingbarigui', process.env.SMARTPROMO_CAMPAIGN_ID_PKB],
  ['parkjacarepagua', process.env.SMARTPROMO_CAMPAIGN_ID_PKJ],
  ['parkshopping', process.env.SMARTPROMO_CAMPAIGN_ID_PKS],
  ['parkshoppingsaocaetano', process.env.SMARTPROMO_CAMPAIGN_ID_PSC],
  ['patio-savassi', process.env.SMARTPROMO_CAMPAIGN_ID_PSS],
  ['ribeiraoshopping', process.env.SMARTPROMO_CAMPAIGN_ID_RBS],
  ['shopping-analia-franco', process.env.SMARTPROMO_CAMPAIGN_ID_SAF],
  ['shoppingsantaursula', process.env.SMARTPROMO_CAMPAIGN_ID_SSU],
  ['shoppingvilaolimpia', process.env.SMARTPROMO_CAMPAIGN_ID_SVO],
  ['villagemall', process.env.SMARTPROMO_CAMPAIGN_ID_VLG],
  ['parque-shopping-maceio', process.env.SMARTPROMO_CAMPAIGN_ID_PQM],
]);

const especialCampaigns = new Map([
  [
    'parkshopping',
    {
      appNameToPrizor: 'Festival de Inverno',
      appNameToSmartPromo: 'Promoção de Pais',
      smartPromoPrimaryCampaignId: null,
      smartPromoSecondaryCampaignId: null,
      defaultSupplier: 'smartpromo',
    },
  ],
  // Exemplos de uso
  // Dois Smartpromo
  // [
  //   'parkshopping',
  //   {
  //     appNameToPrizor: null,
  //     appNameToSmartPromo: 'Promoção de Pais',
  //     smartPromoPrimaryCampaignId: process.env.SMARTPROMO_CAMPAIGN_ID_PRIMARY, // id de campanha quando vem do banner e outros fluxos
  //     smartPromoSecondaryCampaignId: process.env.SMARTPROMO_CAMPAIGN_ID_SECONDARY, // id de campanha quando vem do appNameToSmartPromo
  //     defaultSupplier: 'smartpromo',
  //   },
  // ],
  // Dois fornecedores
  // [
  //   'parkshopping',
  //   {
  //     appNameToPrizor: 'Festival de Inverno',
  //     appNameToSmartPromo: 'Promoção de Pais',
  //     smartPromoPrimaryCampaignId: null,
  //     smartPromoSecondaryCampaignId: null,
  //     defaultSupplier: 'smartpromo', ou 'prizor'
  //   },
  // ],
]);

const goToPromotions = async (history, client, appName, modalitiesFilters = ['Green', 'Gold', 'Silver']) => {
  const { user } = client.readQuery({ query: USER });

  if (!user) return '/signup/phone';

  const { currentShopping } = client.readQuery({ query: CURRENT_SHOPPING });

  await client.query({
    query: USER_VIP,
    skip: !currentShopping || !user,
    variables: { shoppingSlug: currentShopping?.slug, upsert: true, source: 'Start Promo' },
    fetchPolicy: 'network-only',
  });

  const getSupplier = (shoppingSlug) => {
    const smartPromoCampaignId = smartPromoShoppings.get(shoppingSlug);
    const campaignConfig = especialCampaigns.get(shoppingSlug);
    let isSmartPromo = false;
    let isPrizor = false;
    let finalSmartPromoCampaignId = smartPromoCampaignId;

    if (campaignConfig) {
      isPrizor = appName === campaignConfig.appNameToPrizor;
      isSmartPromo = appName === campaignConfig.appNameToSmartPromo;

      if (!isPrizor && !isSmartPromo) {
        isPrizor = campaignConfig.defaultSupplier === 'prizor';
        isSmartPromo = campaignConfig.defaultSupplier === 'smartpromo';
      }

      if (isSmartPromo && campaignConfig.smartPromoSecondaryCampaignId) {
        finalSmartPromoCampaignId =
          appName === campaignConfig.appNameToSmartPromo
            ? campaignConfig.smartPromoSecondaryCampaignId
            : campaignConfig.smartPromoPrimaryCampaignId;
      }
    } else {
      isPrizor = !smartPromoCampaignId;
      isSmartPromo = !!smartPromoCampaignId;
    }

    return { isSmartPromo, isPrizor, smartPromoCampaignId: finalSmartPromoCampaignId };
  };

  const { isSmartPromo, isPrizor, smartPromoCampaignId } = getSupplier(currentShopping.slug);

  const { data: { lprRegistrations = [] } = {} } = await client.query({
    query: PROMOTION_LPR_REGISTRATIONS,
    fetchPolicy: 'network-only',
    skip: !user,
  });

  const {
    data: { fidelitySummary = {} },
  } = await client.query({
    variables: { shoppingSlug: currentShopping?.slug },
    query: SUMMARY,
    fetchPolicy: 'network-only',
    skip: !user || !currentShopping,
  });

  const userHasLprRegistration = !!lprRegistrations?.length || false;

  const getSDKData = () => {
    if (!user || !currentShopping) {
      client.writeData({ data: { pathname: 'promo' } });
      return null;
    }

    let eventData = {
      name: user.name,
      email: user.email,
      documentCPF: user.cpf,
      phone: user.phoneNumber,
    };

    if (user.gender) eventData['gender'] = user.gender === 'M' ? 1 : 2;

    if (user.birthdate) {
      eventData['birthdateDay'] = getDateDay(user.birthdate, 'en-us', 'UTC');
      eventData['birthdateMonth'] = getDateMonth(user.birthdate, 'en-us', 'UTC');
      eventData['birthdateYear'] = getDateYear(user.birthdate, 'en-us', 'UTC');
    }

    if (currentShopping.promotionsContacts && !isSmartPromo) {
      const { email, phoneNumber, whatsappNumber } = currentShopping.promotionsContacts;
      if (email) eventData['contactEmail'] = email;
      if (phoneNumber) {
        eventData['contactPhone'] = phoneNumber;
        eventData['contactPhoneLabel'] = phoneNumberMask(phoneNumber);
      }
      if (whatsappNumber) {
        eventData['contactPhone2'] = `https://wa.me/${whatsappNumber}`;
        eventData['contactPhone2Label'] = phoneNumberMask(whatsappNumber);
      }
    }

    if (isPrizor) {
      eventData['shoppingSlug'] = currentShopping.slug;
      eventData['appLogo'] = 'https:\\/\\/mts-direct.s3.amazonaws.com\\/multi-logo.png';
      eventData['contactTitle'] = 'Precisa de ajuda?';
      eventData['contactContent'] = 'Atendimento durante o horário de funcionamento do shopping';
      eventData['campaignFilters'] = {
        exclusiveFilters: modalitiesFilters,
      };

      eventData.markers = [];

      eventData.markers.push(
        {
          categoryName: 'AcessoMulti',
          markerName: userHasLprRegistration ? 'PossuiAcessoMulti' : 'NaoPossuiAcessoMulti',
        },
        {
          categoryName: 'MultiVocê',
          markerName: fidelitySummary?.plan,
        },
      );
    }

    if (isSmartPromo) {
      const phoneNumber = parsePhoneNumber(eventData['phone']);

      eventData['campaignId'] = smartPromoCampaignId;
      eventData['color'] = '#015c40';
      eventData['isHomolog'] = process.env.SMARTPROMO_HOMOLOG === 'true';
      eventData['phone'] = phoneNumber.formatNational();
      eventData['modality'] = fidelitySummary?.plan;
      eventData['hasLprRegistration'] = userHasLprRegistration;

      eventData['metadata'] = [
        {
          key: 'multiplanId',
          value: user.multiplanId,
        },
        {
          key: 'modality',
          value: fidelitySummary?.plan,
        },
        {
          key: 'modalityEntryDate',
          value: fidelitySummary?.modalityEntryDate,
        },
        {
          key: 'hasLprRegistration',
          value: userHasLprRegistration,
        },
      ];
    }
    return eventData;
  };

  const sdkData = getSDKData();

  const isIos = window?.webkit?.messageHandlers;
  const isAndroid = window?.MultiShoppingAndroid;
  const shopppingsToShowModalUpdate = ['shoppingvilaolimpia', 'shopping-analia-franco'].includes(currentShopping?.slug);
  if (!isDeviceUpdated('promotion') && shopppingsToShowModalUpdate) {
    return '/apps/promotion/modal';
  }

  if (sdkData) {
    let path = '/showcase';
    const platformTap = isSmartPromo ? 'onSmartPromoTap' : 'onPrizorTap';

    if (isIos?.[platformTap]) {
      isIos[platformTap].postMessage(sdkData);
    } else if (isAndroid?.[platformTap]) {
      isAndroid[platformTap](JSON.stringify(sdkData));
    } else {
      path = '/apps/promocoes';
      console.log('platformTap', platformTap);
      console.log('sdkData', sdkData);
    }

    return path;
  }
};

export default goToPromotions;
