import { bugsnag } from 'utils';

// using "then" as iOS/Swift calls to JS functions cannot be async/await
const checkVersion = () => {
  fetch('/version.json', { cache: 'no-cache' })
    .then((response) => {
      if (response.ok) {
        response
          .json()
          .then((version) => {
            if (version['version-web'] !== VERSION) {
              multiStorage.setItem('isForceRefreshing', true);
              location.reload();
            } else {
              multiStorage.setItem('version', VERSION);
              try {
                window.identifyWebVersion();
              } catch (e) {
                bugsnag?.notify(e);
              }
            }
          })
          .catch((error) => bugsnag?.notify(error));
      } else {
        bugsnag?.notify(`Error in checkVersion: ${response.ok} / ${response.status} / ${response.statusText}`);
      }
    })
    .catch((error) => bugsnag?.notify(error));
  return true;
};

export default checkVersion;
