import gql from 'graphql-tag';
import { meSelect, shoppingSelect, store } from './helpers/variables';

const REDIRECT = gql`
  {
    pathname @client
  }
`;

const CURRENT_CART_STORE_ID = gql`
  {
    currentCartStoreId @client
  }
`;

const ME = gql`
  query me {
    me {
      ${meSelect}
    }
  }
`;

const USER = gql`
  query user {
    user @client {
      ${meSelect}
    }
  }
`;

const PLAN = gql`
  query plan {
    plan @client {
      name
      shoppingSlug
    }
  }
`;

const CART = gql`
  query cart($appSlug: String, $shoppingId: ID!) {
    cart(appSlug: $appSlug, shoppingId: $shoppingId) {
      badge
      amount
    }
  }
`;

const USER_VIP = gql`
  query userVip($shoppingSlug: String!, $upsert: Boolean, $source: String) {
    userVip(shoppingSlug: $shoppingSlug, upsert: $upsert, source: $source) {
      id
      plan
      loyaltyPromotions {
        promotion {
          id
          shoppingSlug
          startDate
        }
        optInDate
      }
    }
  }
`;

const STORES = gql`
  query stores(
    $appsSlug: [String!]
    $shoppingId: ID!
    $limit: Int
    $skip: Int
    $floorsIds: [ID!]
    $featured: Boolean
    $gastronomy: Boolean
    $featuredCampaign: Boolean
    $name: String
    $directSellingDeliveryActive: Boolean
    $directSellingDriveThruActive: Boolean
  ) {
    stores(
      appsSlug: $appsSlug
      shoppingId: $shoppingId
      limit: $limit
      skip: $skip
      floorsIds: $floorsIds
      featured: $featured
      gastronomy: $gastronomy
      featuredCampaign: $featuredCampaign
      name: $name
      directSellingDeliveryActive: $directSellingDeliveryActive
      directSellingDriveThruActive: $directSellingDriveThruActive
    ) {
      id
      name
      slug
      images
      pickup
      delivery
      opened
      gastronomy
      hasContract
      deliveryTime
      featuredCampaign
      logoColor {
        id
        original
      }
      floors {
        name
        number
      }
    }
  }
`;

const STORE = gql`
  query store($id: ID, $slug: String, $location: LocationInput) {
    store(id: $id, slug: $slug, location: $location) {
      ... on Store {
        ${store}
      }
      ... on Restaurant {
        ${store}
        reservation{
          active
          phoneNumber
          whatsappNumber
          siteUrl
        }
      }
    }
  }
`;

const PRODUCTS = gql`
  query products(
    $shoppingSlug: String!
    $storeId: ID
    $categoryId: ID
    $text: String
    $limit: Int
    $featured: Boolean
    $topSelling: Boolean
    $skip: Int
    $ascending: String
    $descending: String
  ) {
    products(
      shoppingSlug: $shoppingSlug
      storeId: $storeId
      categoryId: $categoryId
      text: $text
      featured: $featured
      topSelling: $topSelling
      limit: $limit
      skip: $skip
      ascending: $ascending
      descending: $descending
    ) {
      id
      sku
      name
      price
      cover
      slug
      featured
      images
      endDate
      percentage
      previousPrice
      type
      categories {
        id
        name
      }
      store {
        id
        name
      }
    }
  }
`;

const ORDERS = gql`
  query orders($limit: Int, $skip: Int, $statusesValues: [String!], $me: Boolean) {
    orders(limit: $limit, skip: $skip, statusesValues: $statusesValues, me: $me) {
      id
      text
      amount
      createdAt
      totalAmount
      discount
      updatedAt
      orderUpdatedAt
      orderNumber
      stores {
        id
        name
        logoColor {
          id
          original
        }
      }
      status {
        id
        slug
        description
      }
      items {
        id
        quantity
      }
      feedbackResponse {
        id
      }
      payment {
        id
        paymentType {
          id
          slug
        }
        creditCard {
          id
          brand
          lastNumbers
        }
      }
    }
  }
`;

const DELIVERY_TYPE = gql`
  query deliveryType($slug: String!, $shoppingSlug: String!) {
    deliveryType(slug: $slug, shoppingSlug: $shoppingSlug) {
      id
      name
      slug
      noFee
      disabled
      amount
      value
    }
  }
`;

const PAYMENT_TYPE = gql`
  query paymentType($slug: String!) {
    paymentType(slug: $slug) {
      id
      name
      slug
      icon {
        id
        original
      }
    }
  }
`;

const CURRENT_ADDRESS = gql`
  query currentAddress($shoppingId: ID!) {
    currentAddress(shoppingId: $shoppingId) {
      id
      name
      formattedAddress
      location {
        latitude
        longitude
      }
    }
  }
`;

const CURRENT_LOCATION = gql`
  query currentLocation {
    currentLocation @client {
      latitude
      longitude
      accept
      skip
    }
  }
`;

const CURRENT_SHOPPING = gql`
  query currentShopping {
    currentShopping @client {
      ${shoppingSelect}
    }
  }
`;

const SHOPPING = gql`
  query shopping ($slug: String, $id: ID) {
    shopping(slug: $slug, id: $id) {
      ${shoppingSelect}
    }
  }
`;

const SELECTED_ADDRESS = gql`
  query selectedAddress {
    selectedAddress @client {
      id
      name
      formattedAddress
      location {
        latitude
        longitude
      }
    }
  }
`;

const SELECTED_DELIVERY_TYPE = gql`
  query selectedDeliveryType @client {
    selectedDeliveryType {
      id
      name
      slug
      noFee
      disabled
      amount
    }
  }
`;

const SELECTED_MOVIE_DATE = gql`
  query selectedMovieDate @client {
    selectedMovieDate {
      movieDate
      moviesDate
      maxDate
    }
  }
`;

const CATEGORIES = gql`
  query categories {
    categories {
      id
      name
      slug
      cover {
        id
        original
      }
    }
  }
`;

const CURRENT_SEGMENT_IDS = gql`
  query currentSegmentIds {
    currentSegmentIds @client {
      shoppingId
      segmentIds
      gastronomy
    }
  }
`;

const SEGMENTS = gql`
  query segments($limit: Int, $skip: Int, $shoppingFeaturedId: String, $gastronomy: Boolean, $featured: Boolean) {
    segments(
      limit: $limit
      skip: $skip
      shoppingFeaturedId: $shoppingFeaturedId
      gastronomy: $gastronomy
      featured: $featured
    ) {
      id
      name
      slug
      cover {
        id
        original
      }
    }
  }
`;

const APP = gql`
  query app($shoppingId: ID!, $slug: String!) {
    app(shoppingId: $shoppingId, slug: $slug) {
      id
      slug
      name
      logo {
        id
        original
      }
    }
  }
`;

const HAS_APP = gql`
  query hasApp($shoppingId: ID!, $slug: String!) {
    hasApp(shoppingId: $shoppingId, slug: $slug)
  }
`;

const SEARCH_TEXT = gql`
  {
    searchText @client
  }
`;

const ORDER_FILTER = gql`
  {
    orderFilter @client
  }
`;

const PAYMENT_SELECTED = gql`
  {
    paymentSelected @client {
      id
      name
      slug
      icon {
        id
        original
      }
    }
  }
`;

const CREDIT_CARD_SELECTED = gql`
  {
    creditCardSelected @client
  }
`;

const PAYMENT_CHANGE = gql`
  {
    paymentChange @client
  }
`;

const DELIVERY_NOTICE = gql`
  {
    deliveryNotice @client
  }
`;

const ORDER_CHAT_UPDATED = gql`
  {
    orderOrChatUpdated @client
  }
`;

const CURRENT_ORDER_STATUS = gql`
  {
    orderUpdated @client
  }
`;

const HAS_CART_IN_ANOTHER_STORE = gql`
  query hasCartInAnotherStore($appSlug: String!, $shoppingId: ID!, $storeId: ID!) {
    hasCartInAnotherStore(appSlug: $appSlug, shoppingId: $shoppingId, storeId: $storeId)
  }
`;

const SHOPPING_DELIVERY = gql`
  query shoppingDelivery($shoppingId: ID!, $location: LocationInput!) {
    shoppingDelivery(shoppingId: $shoppingId, location: $location) {
      foods
      goods
    }
  }
`;

const HAS_VALID_COUPON = gql`
  query hasValidCoupon($shoppingSlug: String!) {
    hasValidCoupon(shoppingSlug: $shoppingSlug)
  }
`;

const SHOPPINGS = gql`
  query shoppings($location: LocationInput, $limit: Int) {
    shoppings(location: $location, limit: $limit) {
      ${shoppingSelect}
      distance(location: $location)
    }
  }
`;

const CAMPAIGN_STARTED = gql`
  {
    campaignStarted @client
  }
`;

const HAVE_VALID_COUPON = gql`
  query haveValidCoupon {
    haveValidCoupon
  }
`;

const CURRENT_COUPON = gql`
  query currentCoupon {
    currentCoupon @client {
      name
      noFee
      discount
      freeShipping
      status
    }
  }
`;

const CHAT_MESSAGES = gql`
  query messages($chatId: ID!) {
    messages(chatId: $chatId) {
      id
      text
      createdAt
      user {
        id
        name
      }
      attachments {
        id
        file {
          id
          small
        }
      }
    }
  }
`;

const SUMMARY = gql`
  query fidelitySummary($shoppingSlug: String!) {
    fidelitySummary(shoppingSlug: $shoppingSlug) {
      currentBalance
      balanceUpdatedIn
      plan
      upgradePoints
      nextTierUpgradeBalance
      nextTierPointsExpirationDate
      nextTierMinThreshold
      currentTierMinThreshold
      maintenancePoints
      currentTierMinThreshold
      modalityEntryDate
      nextTierAssessmentDate
      currentTierMaintenanceBalance
    }
  }
`;

const CURRENT_THIRD_PARTY_DELIVERY = gql`
  query currentThirdPartyDelivery {
    currentThirdPartyDelivery @client {
      name
      phoneNumber
      active
    }
  }
`;

const PARKING_PROMOTIONS = gql`
  query parkingPromotions($limit: Int, $skip: Int, $shoppingSlug: String!, $userVipBenefitId: ID) {
    parkingPromotions(limit: $limit, skip: $skip, shoppingSlug: $shoppingSlug, userVipBenefitId: $userVipBenefitId) {
      userVipBenefitId
      name
      description
      promoId
      cover
      endDate
    }
  }
`;

const LLV_SEARCH = gql`
  query llvSearch($shoppingSlug: String!, $storeId: ID, $limit: Int, $skip: Int) {
    llvSearch(shoppingSlug: $shoppingSlug, storeId: $storeId, q: "", limit: $limit, skip: $skip) {
      id
      name
      slug
      price
      images
      endDate
      percentage
      description
      previousPrice
      type
      store {
        ... on Store {
          id
          name
          slug
          floors {
            id
            name
            number
            map
          }
        }
        ... on Restaurant {
          id
          name
          slug
          floors {
            id
            name
            number
            map
          }
        }
      }
    }
  }
`;

const USER_CREDIT_CARDS = gql`
  query creditCards {
    creditCards {
      id
      brand
      lastNumbers
    }
  }
`;

const USER_CREDIT_CARD = gql`
  query creditCard {
    creditCard {
      id
      name
      brand
      lastNumbers
      validThru
    }
  }
`;

const CHECK_INS = gql`
  query checkIns(
    $shoppingId: ID
    $storeId: ID
    $active: String
    $limit: Int
    $skip: Int
    $ordering: CheckInOrdering
    $featured: Boolean
    $categoriesIds: [ID]
    $categoriesNames: [String]
  ) {
    checkIns(
      shoppingId: $shoppingId
      storeId: $storeId
      active: $active
      limit: $limit
      skip: $skip
      ordering: $ordering
      featured: $featured
      categoriesIds: $categoriesIds
      categoriesNames: $categoriesNames
    ) {
      id
      slug
      active
      pageTitle
      order
      exclusive
      cover {
        id
        original
      }
      store {
        id
        name
        logoColor {
          id
          original
        }
        secondaryLogo {
          id
          original
        }
      }
      shopping {
        name
      }
      establishmentName
      endDate
      isFavorite
      isSoldOut
    }
  }
`;

const ENTITY_CATEGORIES = gql`
  query entityCategories(
    $name: String
    $active: Boolean
    $shoppingId: ID
    $entity: String
    $ordering: [Sort]
    $skip: Int
    $limit: Int
  ) {
    getEntityCategories(
      name: $name
      active: $active
      shoppingId: $shoppingId
      entity: $entity
      ordering: $ordering
      skip: $skip
      limit: $limit
    ) {
      id
      name
      active
    }
  }
`;

const GET_RECOMMENDED_CUPONS = gql`
  query getCouponsRecommendations($shoppingSlug: String!, $deviceId: String, $location: LocationInput, $limit: Int) {
    getCouponsRecommendations(shoppingSlug: $shoppingSlug, deviceId: $deviceId, location: $location, limit: $limit) {
      track
      checkIn {
        id
        slug
        active
        pageTitle
        order
        exclusive
        isFavorite
        cover {
          id
          original
        }
        store {
          id
          name
          logoColor {
            id
            original
          }
          secondaryLogo {
            id
            original
          }
        }
        shopping {
          name
        }
        establishmentName
        endDate
      }
    }
  }
`;

const COUNT_CHECK_INS_FAVORITES = gql`
  query countCheckInsFavorites($shoppingId: ID!) {
    countCheckInsFavorites(shoppingId: $shoppingId)
  }
`;

const COUNT_USER_CHECK_INS = gql`
  query countUserCheckIns($shoppingId: ID!, $hasIssued: Boolean) {
    countUserCheckIns(shoppingId: $shoppingId, hasIssued: $hasIssued)
  }
`;

const ANONYMOUS_MULTIPLAN_ID = gql`
  query anonymousMultiplanId {
    anonymousMultiplanId
  }
`;

const COUNT_CHECK_INS = gql`
  query countShoppingCheckIns($shoppingId: ID) {
    countShoppingCheckIns(shoppingId: $shoppingId)
  }
`;

const GET_USER_VIEW_HISTORY = gql`
  query getUserViewHistory($entity: String!, $entityId: ID!, $viewSlug: String) {
    getUserViewHistory(entity: $entity, entityId: $entityId, viewSlug: $viewSlug)
  }
`;

const SHOPPING_EVENTS = gql`
  query getShoppingEvents($categoriesIds: [ID], $shoppingId: ID, $active: Boolean, $limit: Int, $skip: Int) {
    getShoppingEvents(
      categoriesIds: $categoriesIds
      shoppingId: $shoppingId
      active: $active
      limit: $limit
      skip: $skip
    ) {
      id
      slug
      title
      type
      link
      startDate
      endDate
      active
      featured
      limitedSeats
      showLastDays
      duration
      lastDays
      checkIns {
        id
        isSoldOut
      }
      categories {
        name
      }
      carousel {
        id
        original
      }
    }
  }
`;

const BENEFITS = gql`
  query benefits(
    $limit: Int
    $skip: Int
    $shoppingSlug: String!
    $onlyFavorites: Boolean
    $modalities: [LoyaltyModality]
  ) {
    benefits(
      limit: $limit
      skip: $skip
      shoppingSlug: $shoppingSlug
      onlyFavorites: $onlyFavorites
      modalities: $modalities
    ) {
      id
      title
      description
      image {
        url
        type
      }
      category
      isFavorite
      endDate
      issueDate
      points
      type
      store {
        name
      }
      programming
      modality {
        tierName
        tierId
        eligibleTiers
      }
      parkingDiscountId
    }
  }
`;

const SHOPPING_PLAN = gql`
  query shoppingPlan($shoppingSlug: String, $planModality: String, $planName: String) {
    shoppingPlan(shoppingSlug: $shoppingSlug, planModality: $planModality, planName: $planName) {
      minimumPoints
    }
  }
`;

const SHOPPINGS_MODALITIES = gql`
  query plan($shoppingSlug: String) {
    plan(shoppingSlug: $shoppingSlug) {
      modalities {
        name
        upgradePoints
        active
      }
    }
  }
`;

const COUNT_BENEFITS = gql`
  query countBenefits($shoppingSlug: String!, $modalities: [LoyaltyModality], $active: Boolean) {
    countBenefits(shoppingSlug: $shoppingSlug, modalities: $modalities, active: $active)
  }
`;

const APPS = gql`
  query apps($shoppingId: ID!, $skip: Int) {
    apps(shoppingId: $shoppingId, skip: $skip) {
      id
      name
      slug
      externalUrl
      color
      featured
      cover {
        id
        original
      }
      icon {
        id
        original
      }
      showLoginScreen
    }
  }
`;

const ACTUAL_PRIVACY_POLICY = gql`
  query actualPrivacyPolicy {
    actualPrivacyPolicy {
      id
      content
      termsOfUse
      version
    }
  }
`;

const PRE_SIGNED_URL = gql`
  query getPreSignedUrl($type: String!, $extension: String!) {
    getPreSignedUrl(type: $type, extension: $extension) {
      uploadUrl
      accessUrl
    }
  }
`;

const GET_USER_CHECK_INS = gql`
  query userCheckIns($userId: ID, $shoppingId: ID, $hasIssued: Boolean, $hasUsed: Boolean, $limit: Int, $skip: Int) {
    userCheckIns(
      userId: $userId
      shoppingId: $shoppingId
      hasIssued: $hasIssued
      hasUsed: $hasUsed
      limit: $limit
      skip: $skip
    ) {
      id
      user {
        name
      }
      checkIn {
        id
        slug
        active
        pageTitle
        order
        exclusive
        endDate
        cover {
          id
          original
        }
        store {
          id
          name
          logoColor {
            id
            original
          }
          secondaryLogo {
            id
            original
          }
        }
        shopping {
          name
        }
        checkInCategories {
          id
          name
        }
        isHidden
      }
      checkedInAt
      counter
      code
      usedAt
      hasCode
    }
  }
`;

const BANNERS = gql`
  query banners(
    $shoppingsIds: [ID]
    $limit: Int
    $skip: Int
    $ordering: BannerOrdering
    $path: String
    $position: Int
  ) {
    banners(
      shoppingsIds: $shoppingsIds
      limit: $limit
      skip: $skip
      ordering: $ordering
      path: $path
      position: $position
    ) {
      id
      name
      link
      position
      showLoginScreen
      cover {
        id
        original
      }
    }
  }
`;

const CHECK_USER_REGISTER = gql`
  query checkUserRegister {
    checkUserRegister {
      name
      birthdate
      email
      gender
      cpf
      privacyPolicy {
        privacyPolicy
        acceptedDatePrivacyPolicy
        version
      }
      multiTerms {
        privacyPolicy
        terms
      }
    }
  }
`;

const MULTI_THEATERS = gql`
  query getMultiTheaters(
    $shoppingId: ID
    $title: String
    $active: Boolean
    $periodStart: Date
    $periodEnd: Date
    $limit: Int
    $skip: Int
    $ordering: MultiTheaterOrdering
  ) {
    getMultiTheaters(
      shoppingId: $shoppingId
      active: $active
      title: $title
      periodStart: $periodStart
      periodEnd: $periodEnd
      limit: $limit
      skip: $skip
      ordering: $ordering
    ) {
      id
      updatedAt
      createdAt
      active
      benefits {
        id
        title
      }
      ticketValue
      period
      ageRange
      title
      preSale
      isSoldOut
      description
      location
      link
      startDate
      endDate
      duration
      shopping {
        id
        name
      }
      carousel {
        id
        original
      }
    }
  }
`;

export {
  REDIRECT,
  CURRENT_CART_STORE_ID,
  ME,
  USER,
  USER_VIP,
  STORES,
  STORE,
  PRODUCTS,
  ORDERS,
  PAYMENT_TYPE,
  DELIVERY_TYPE,
  CURRENT_ADDRESS,
  CURRENT_LOCATION,
  CURRENT_SHOPPING,
  SHOPPING,
  SEGMENTS,
  SELECTED_ADDRESS,
  SELECTED_DELIVERY_TYPE,
  SELECTED_MOVIE_DATE,
  CATEGORIES,
  CURRENT_SEGMENT_IDS,
  APP,
  PLAN,
  HAS_APP,
  HAVE_VALID_COUPON,
  SEARCH_TEXT,
  ORDER_FILTER,
  PAYMENT_SELECTED,
  CREDIT_CARD_SELECTED,
  PAYMENT_CHANGE,
  DELIVERY_NOTICE,
  SHOPPING_DELIVERY,
  ORDER_CHAT_UPDATED,
  CURRENT_ORDER_STATUS,
  HAS_CART_IN_ANOTHER_STORE,
  HAS_VALID_COUPON,
  SHOPPINGS,
  CAMPAIGN_STARTED,
  CURRENT_COUPON,
  CART,
  SUMMARY,
  CHAT_MESSAGES,
  CURRENT_THIRD_PARTY_DELIVERY,
  PARKING_PROMOTIONS,
  LLV_SEARCH,
  USER_CREDIT_CARDS,
  USER_CREDIT_CARD,
  CHECK_INS,
  GET_RECOMMENDED_CUPONS,
  COUNT_CHECK_INS_FAVORITES,
  COUNT_USER_CHECK_INS,
  ANONYMOUS_MULTIPLAN_ID,
  COUNT_CHECK_INS,
  GET_USER_VIEW_HISTORY,
  ENTITY_CATEGORIES,
  SHOPPING_EVENTS,
  BENEFITS,
  COUNT_BENEFITS,
  SHOPPING_PLAN,
  APPS,
  ACTUAL_PRIVACY_POLICY,
  PRE_SIGNED_URL,
  SHOPPINGS_MODALITIES,
  GET_USER_CHECK_INS,
  BANNERS,
  CHECK_USER_REGISTER,
  MULTI_THEATERS,
};
