import { bugsnag } from 'utils';

const checkUserData = ({ birthdate, cpf, email, gender, name, privacyPolicy, pathname }) => {
  const isUserRegisterValid =
    birthdate === 'valid' && cpf === 'valid' && email === 'valid' && gender === 'valid' && name === 'valid';

  const isPrivacyPolicyValid =
    privacyPolicy?.acceptedDatePrivacyPolicy === 'valid' &&
    privacyPolicy?.privacyPolicy === 'valid' &&
    privacyPolicy?.version === 'valid';

  try {
    const trackData = {
      mixpanel: {
        name: 'Check User Register',
        properties: {
          birthdate,
          cpf,
          email,
          gender,
          name,
          acceptedDatePrivacyPolicy: privacyPolicy?.acceptedDatePrivacyPolicy,
          privacyPolicy: privacyPolicy?.privacyPolicy,
          version: privacyPolicy?.version,
          pathname,
        },
      },
    };
    window.track(trackData);
  } catch (e) {
    bugsnag?.notify(e);
  }

  return { isUserRegisterValid, isPrivacyPolicyValid };
};

export default checkUserData;
