import { bugsnag } from 'utils';

const getLastPaymentType = () => {
  const lastPaymentType = multiStorage.getItem('lastPaymentType');
  if (typeof lastPaymentType === 'string') {
    try {
      const paymentType = JSON.parse(lastPaymentType);
      if (paymentType.__typename === 'PaymentType') return paymentType;
      if (paymentType.value && typeof paymentType.value === 'object') {
        const paymentTypeOld = paymentType.value;
        if (paymentTypeOld.__typename === 'PaymentType') {
          multiStorage.setItem('lastPaymentType', JSON.stringify(paymentTypeOld));
          return paymentTypeOld;
        }
      }
    } catch (e) {
      bugsnag?.notify(e);
    }
  }
  return null;
};

export default getLastPaymentType;
