import { useApolloClient } from '@apollo/react-hooks';
import { CURRENT_COUPON } from 'services/apollo/queries';

const clearCurrentCoupon = async () => {
  const client = useApolloClient();
  const clear = () => {
    client.writeQuery({
      query: CURRENT_COUPON,
      data: {
        currentCoupon: {
          name: null,
          discount: null,
          status: null,
          noFee: false,
          freeShipping: false,
          __typename: 'CurrentCounpon',
        },
      },
    });
  };
  return clear;
};

export default clearCurrentCoupon;
