import avatarInitials from './functions/avatarInitials';
import bugsnag from './functions/bugsnag';
import capitalize from './functions/capitalize';
import classNames from './functions/classNames';
import checkVersion from './functions/checkVersion';
import { compressImage, getBase64, dataURLtoFile, createFile } from './functions/compressImage';
import currencyFormat from './functions/currencyFormat';
import productsByStore from './functions/productsByStore';
import dcFeeCalc from './functions/dcFeeCalc';
import DDDRemove from './functions/dddRemove';
import IsOpenStore from './functions/isOpenStore';
import extractItemFromObject from './functions/extractItemFromObject';
import fadeOut from './functions/fadeOut';
import formatCPF from './functions/formatCPF';
import localTtlStorage from './functions/localTtlStorage';
import formatDayAndMonth from './functions/formatDayAndMonth';
import formatDatePtBR from './functions/formatDatePtBR';
import formatDistance from './functions/formatDistance';
import formatShortHour from './functions/formatShortHour';
import formatUTCDate from './functions/formatUTCDate';
import getFormattedDate from './functions/getFormattedDate';
import getLocation from './functions/getLocation';
import getStoreBusinessHours from './functions/getStoreBusinessHours';
import goToPromotions from './functions/goToPromotions';
import openParkingSDK from './functions/openParkingSDK';
import goToWifi from './functions/goToWifi';
import getCurrentDateHour from './functions/getCurrentDateHour';
import getDateDay from './functions/getDateDay';
import getDateMonth from './functions/getDateMonth';
import getWeekDay from './functions/getWeekDay';
import getDateYear from './functions/getDateYear';
import getDeliveryHour from './functions/getDeliveryHour';
import getUserLocation from './functions/getUserLocation';
import { goToSearch, resetSearchQueries } from './functions/goToSearch';
import getNearestShopping from './functions/getNearestShopping';
import isIphoneX from './functions/isIphoneX';
import textPlural from './functions/textPlural';
import textTruncate from './functions/textTruncate';
import showGraphQLError from './functions/showGraphQLError';
import showDeliveryTime from './functions/showDeliveryTime';
import sumReducer from './functions/sumReducer';
import withInfiniteScroll from './functions/withInfiniteScroll';
import emailMask from './functions/emailMask';
import getEnvironment from './functions/getEnvironment';
import isSameDay from './functions/isSameDay';
import daysDifference from './functions/daysDifference';
import informationsDevice from './functions/informationsDevice';
import swipeDetect from './functions/swipeDetect';
import isDateAfter from './functions/isDateAfter';
import openExternalContent from './functions/openExternalContent';
import uploadFiles from './functions/uploadFiles';
import maskUserName from './functions/maskUserName';
import maskUserCpf from './functions/maskUserCpf';
import getDescription from './functions/getDescription';
import translateType from './functions/translateType';
import trackCreateOrderError from './functions/trackCreateOrderError';
import trackProceedWithOfflinePayment from './functions/trackProceedWithOfflinePayment';
import openParking from './functions/openParking';
import formatPhone from './functions/formatPhone';
import handlePhone from './functions/handlePhone';
import handleWhatsapp from './functions/handleWhatsapp';
import handleWebsite from './functions/handleWebsite';
import trackClickedStoreCard from './functions/trackClickedStoreCard';
import trackClickedStoreModalContact from './functions/trackClickedStoreModalContact';
import trackClickedStoreContact from './functions/trackClickedStoreContact';
import trackClickedStoreReservation from './functions/trackClickedStoreReservation';
import trackClickedStoreReservationContact from './functions/trackClickedStoreReservationContact';
import shoppingByUrl from './functions/shoppingByUrl';
import phoneNumberMask from './functions/phoneNumberMask';
import getShoppingBusinessHours from './functions/getShoppingBusinessHours';
import buildTags from './functions/buildTags';
import getMockedStore from './functions/getMockedStore';
import formatHour from './functions/formatHour';
import isDirectSellingWeb from './functions/isDirectSellingWeb';
import clearCurrentCoupon from './functions/clearCurrentCoupon';
import getDeliveryFee from './functions/getDeliveryFee';
import getDCFee from './functions/getDCFee';
import getOrderTotalAmount from './functions/getOrderTotalAmount';
import saveTempForm from './functions/saveTempForm';
import updateTempForm from './functions/updateTempForm';
import clearTempForm from './functions/clearTempForm';
import getTempForm from './functions/getTempForm';
import checkStoreVersion from './functions/checkStoreVersion';
import trackFirebaseEvents from './functions/trackFirebaseEvents';
import hasNativeCamera from './functions/hasNativeCamera';
import cleanPhoneNumber from './functions/cleanPhoneNumber';
import handleActiveChat from './functions/handleActiveChat';
import setLoading from './functions/setLoading';
import removeLoading from './functions/removeLoading';
import getDistanceFromLatLongInKm from './functions/getDistanceFromLatLongInKm';
import openInAppReview from './functions/openInAppReview';
import trackClickedDirectSellingStorePage from './functions/trackClickedDirectSellingStorePage';
import trackClickedDirectSellingGastronomyPage from './functions/trackClickedDirectSellingGastronomyPage';
import loadAndOpenDcChat from './functions/loadAndOpenDcChat';
import { hasDifferenceDays } from './functions/daysDifference';
import compareValues from './functions/compareValues';
import goToApp from './functions/goToApp';
import formatDateString from './functions/formatDateString';
import trackClickedClaimedBenefit from './functions/trackClickedClaimedBenefits';
import trackClickedUseBenefit from './functions/trackClickedUseBenefit';
import checkInFavoriteTracking from './functions/checkInFavoriteTracking';
import goToShoppingsAndSetLocation from './functions/goToShoppingsAndSetLocation';
import handleShare from './functions/handleShare';
import shareCheckInTracking from './functions/shareCheckInTracking';
import formatHourAndMinutes from './functions/formatHourAndMinutes';
import shareEventTracking from './functions/shareEventTracking';
import formatNumberLeadingZero from './functions/formatNumberLeadingZero';
import { skipEnterAnimationPaths, skipExitAnimationPaths } from './skipAnimationPaths';
import getCurrentPath from './functions/getCurrentPath';
import getVariant from './functions/getVariant';
import changeShopping from './functions/changeShopping';
import getFormattedDateAndHour from './functions/getFormattedDateAndHour';
import getFormattedShoppings from './functions/getFormattedShoppings';
import formatDateRange from './functions/formatDateRange';
import { formatDateToWeekday } from './formatDateToWeekDay';
import { currencyFormatBRL } from './functions/currencyFormatBRL';
import { formatDataInTime } from './functions/formatDataInTime';
import shareBenefitTracking from './functions/shareBenefitTracking';
import { formatNumberWithDot } from './functions/formatNumberWithDot';
import checkUserData from './functions/checkUserData';
import getImageFileExtension from './functions/getImageFileExtension';
import groupingTransactionsByMonth from './functions/groupingTransactionsByMonth';
import getDaysInMonth from './functions/getDaysInMonth';
import todayDifferenceIsBigger from './functions/todayDifferenceIsBigger';
import {
  marketFreePassShoppings,
  gymFreePassShoppings,
  shoppingsWithoutTicketGate,
  virtualTicketShoppings,
  ITFBarcodeShoppings,
  EANBarcodeShoppings,
  shoppingsWithPIX,
  shoppingsSdkMobits,
  shoppingSlugToName,
  shoppingLocations,
  shoppingWithMvcDiscount,
} from './shoppingsConditionals';
import getSlug from './functions/getSlug';
import copyToClipboard from './functions/copyToClipboard';
import isDeviceUpdated from './functions/isDeviceUpdated';
import differenceInHours from './functions/differenceInHours';
import monthsOfYear from './months';

export {
  copyToClipboard,
  avatarInitials,
  bugsnag,
  capitalize,
  checkVersion,
  classNames,
  compressImage,
  getBase64,
  dataURLtoFile,
  createFile,
  withInfiniteScroll,
  currencyFormat,
  dcFeeCalc,
  DDDRemove,
  IsOpenStore,
  extractItemFromObject,
  fadeOut,
  formatCPF,
  formatDayAndMonth,
  formatDatePtBR,
  formatDistance,
  formatShortHour,
  formatUTCDate,
  getCurrentDateHour,
  getDateDay,
  getDateMonth,
  getWeekDay,
  getDateYear,
  getDeliveryHour,
  getFormattedDate,
  getLocation,
  getStoreBusinessHours,
  goToPromotions,
  openParkingSDK,
  goToWifi,
  getUserLocation,
  getNearestShopping,
  isIphoneX,
  showGraphQLError,
  showDeliveryTime,
  sumReducer,
  textPlural,
  textTruncate,
  goToSearch,
  resetSearchQueries,
  emailMask,
  getEnvironment,
  isSameDay,
  daysDifference,
  informationsDevice,
  swipeDetect,
  isDateAfter,
  openExternalContent,
  uploadFiles,
  maskUserName,
  maskUserCpf,
  getDescription,
  translateType,
  trackCreateOrderError,
  trackProceedWithOfflinePayment,
  openParking,
  formatPhone,
  handlePhone,
  handleWhatsapp,
  handleWebsite,
  trackClickedStoreCard,
  trackClickedStoreModalContact,
  trackClickedStoreReservationContact,
  trackClickedStoreContact,
  trackClickedUseBenefit,
  shoppingByUrl,
  phoneNumberMask,
  getShoppingBusinessHours,
  buildTags,
  getMockedStore,
  formatHour,
  isDirectSellingWeb,
  clearCurrentCoupon,
  productsByStore,
  getDeliveryFee,
  getDCFee,
  getOrderTotalAmount,
  saveTempForm,
  updateTempForm,
  clearTempForm,
  getTempForm,
  checkStoreVersion,
  trackFirebaseEvents,
  hasNativeCamera,
  localTtlStorage,
  cleanPhoneNumber,
  handleActiveChat,
  setLoading,
  removeLoading,
  getDistanceFromLatLongInKm,
  openInAppReview,
  trackClickedStoreReservation,
  trackClickedDirectSellingStorePage,
  trackClickedDirectSellingGastronomyPage,
  hasDifferenceDays,
  compareValues,
  loadAndOpenDcChat,
  goToApp,
  formatDateString,
  trackClickedClaimedBenefit,
  checkInFavoriteTracking,
  goToShoppingsAndSetLocation,
  handleShare,
  shareCheckInTracking,
  formatHourAndMinutes,
  shareEventTracking,
  formatNumberLeadingZero,
  skipEnterAnimationPaths,
  skipExitAnimationPaths,
  getCurrentPath,
  getVariant,
  changeShopping,
  getFormattedDateAndHour,
  getFormattedShoppings,
  formatDateRange,
  formatDateToWeekday,
  currencyFormatBRL,
  formatDataInTime,
  shareBenefitTracking,
  formatNumberWithDot,
  checkUserData,
  getImageFileExtension,
  groupingTransactionsByMonth,
  getDaysInMonth,
  todayDifferenceIsBigger,
  marketFreePassShoppings,
  gymFreePassShoppings,
  shoppingsWithoutTicketGate,
  virtualTicketShoppings,
  ITFBarcodeShoppings,
  EANBarcodeShoppings,
  shoppingsWithPIX,
  shoppingsSdkMobits,
  getSlug,
  shoppingSlugToName,
  shoppingLocations,
  isDeviceUpdated,
  differenceInHours,
  monthsOfYear,
  shoppingWithMvcDiscount,
};
