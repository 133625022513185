import { initialState } from 'services/apollo/helpers/variables';
import { SEARCH_TEXT, ORDER_FILTER, CURRENT_SEGMENT_IDS } from 'services/apollo/queries';

const resetSearchQueries = (client) => {
  client.writeQuery({ query: SEARCH_TEXT, data: { searchText: '' } });
  client.writeQuery({ query: ORDER_FILTER, data: { orderFilter: initialState.orderFilter } });
  client.writeQuery({
    query: CURRENT_SEGMENT_IDS,
    data: { currentSegmentIds: initialState.segmentsIds },
  });
};

const goToSearch = ({ path, client, history }) => {
  if (!path || !client || !history) return;

  resetSearchQueries(client);
  history.push(path);
};

export default goToSearch;
export { goToSearch, resetSearchQueries };
