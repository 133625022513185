import { statusToast } from 'components/StatusToast';
import { shareCheckInTracking, shareEventTracking, shareBenefitTracking } from 'utils';
import copyToClipboard from '../copyToClipboard';

const shoppingSlugToAcronym = new Map([
  ['bh-shopping', 'bhs'],
  ['barrashopping', 'brs'],
  ['barrashoppingsul', 'bss'],
  ['diamondmall', 'dmm'],
  ['jundiaishopping', 'jds'],
  ['morumbishopping', 'mbs'],
  ['parkshoppingcampogrande', 'pcg'],
  ['parkshopping-canoas', 'pcn'],
  ['parkshoppingbarigui', 'pkb'],
  ['parkjacarepagua', 'pkj'],
  ['parkshopping', 'pks'],
  ['parkshoppingsaocaetano', 'psc'],
  ['parque-shopping-maceio', 'pqm'],
  ['patio-savassi', 'pss'],
  ['ribeiraoshopping', 'rbs'],
  ['shopping-analia-franco', 'saf'],
  ['shoppingsantaursula', 'ssu'],
  ['shoppingvilaolimpia', 'svo'],
  ['villagemall', 'vlm'],
]);

const shareFrom = {
  checkIn: {
    name: 'Ingresso',
    endPoint: 'check-in',
    campaign: 'CheckIn-zap',
  },
  event: {
    name: 'Evento',
    endPoint: 'apps/eventos',
    campaign: 'Eventos-zap',
  },
  benefit: {
    name: 'Benefício',
    endPoint: 'apps/multivoce/beneficios/tickets',
    campaign: 'Beneficios-zap',
  },
  theater: {
    name: 'Teatro',
    endPoint: 'apps/teatro-multi',
    campaign: 'Teatro-zap',
  },
};

const handleShare = ({ shareObj, shoppingSlug, isWhatsapp, isCopyLink }) => {
  const benefitVariant = shareObj.variant ? `/${shareObj.variant}` : '';
  const adjustURL = `https://wku8.adj.st/${shareFrom[shareObj.from].endPoint}/${shareObj.slug || shareObj.id}?adj_t=${
    process.env.TRACKER
  }%26adj_campaign=${shareFrom[shareObj.from].campaign}%26adj_adgroup=${shoppingSlugToAcronym.get(
    shoppingSlug,
  )}%26adj_fallback=${process.env.FALLBACK}%26adj_redirect_macos=${
    process.env.REDIRECT_MACOS
  }%26adj_deep_link=meumulti%3A%2F%2Fcontent%2F${shareFrom[shareObj.from].endPoint}/${
    shareObj.slug || shareObj.id
  }${benefitVariant}`;

  if (isWhatsapp) window.open(`https://api.whatsapp.com/send?text=${adjustURL}`);
  if (isCopyLink) {
    copyToClipboard(adjustURL);
    statusToast({
      type: 'success',
      title: `Link do  ${shareFrom[shareObj.from].name} Copiado`,
      text: 'Compartilhe!',
    });
  }

  if (shareObj.from === 'checkIn')
    shareCheckInTracking({
      shareObj,
      isWhatsapp,
      isCopyLink,
    });
  if (shareObj.from === 'event')
    shareEventTracking({
      shareObj,
      isWhatsapp,
      isCopyLink,
    });
  if (shareObj.from === 'benefit') shareBenefitTracking({ shareObj, isWhatsapp, isCopyLink });
};

export default handleShare;
