import { SHOPPINGS } from 'services/apollo/queries';

const getNearestShopping = async (client, currentLocation) => {
  const {
    data: { shoppings },
  } = await client.query({
    query: SHOPPINGS,
    variables: {
      location: { latitude: currentLocation.latitude, longitude: currentLocation.longitude },
    },
  });

  const nearestShopping = shoppings[0];
  if (nearestShopping.slug === 'villagemall') return shoppings.find((shopping) => shopping.slug === 'barrashopping');
  return nearestShopping;
};

export default getNearestShopping;
