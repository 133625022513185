const isAndroid = !!window?.MultiShoppingAndroid?.requestPermissionLocation;
const isIOS = !!window?.webkit?.messageHandlers?.requestPermissionLocation;

const sleep = (ms) => {
  return new Promise((r) => setTimeout(r, ms));
};

const getLocation = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      if (!isIOS) {
        if (isAndroid) {
          window.MultiShoppingAndroid.requestPermissionLocation();
        }
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          maximumAge: 5000,
          timeout: 5000,
        });
      } else {
        let currentStatus = 'notDetermined';
        let currentLatitude = 0;
        let currentLongitude = 0;

        window.webkit.messageHandlers.requestPermissionLocation.postMessage({});
        window.onRequestPermissionLocation = (status) => {
          currentStatus = status;
          if (currentStatus === 'authorizedWhenInUse' || currentStatus === 'authorizedAlways') {
            window.webkit.messageHandlers.getCurrentPosition.postMessage({});
          }
        };
        window.onGetCurrentPosition = (latitude, longitude) => {
          currentLatitude = latitude;
          currentLongitude = longitude;
        };

        const endTime = Date.now() + 5000;
        do {
          if (currentStatus === 'denied' || (currentLatitude !== 0 && currentLongitude !== 0)) break;
          await sleep(500);
        } while (Date.now() < endTime);
        if (currentStatus === 'denied') reject({ skip: true });
        if (currentLatitude === 0 || currentLongitude === 0) reject();
        else
          resolve({
            coords: {
              latitude: currentLatitude,
              longitude: currentLongitude,
            },
          });
      }
    } catch (e) {
      console.error("Couldn't get location");
    }
  });
};

export default getLocation;
