import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const Flex = ({
  direction,
  align = 'center',
  justify = 'center',
  gap,
  wrap,
  children,
  className,
  bgColor,
  borderRadius,
  fullWidth = false,
  margin,
  padding,
  height,
  width,
  pb,
  pt,
  pl,
  pr,
  ml,
  mr,
  mt,
  mb,
  style: styleProp,
  ...props
}) => {
  let style = {
    gap,
    flexDirection: direction,
    alignItems: align,
    justifyContent: justify,
    flexWrap: wrap,
    ...(padding && { padding }),
    ...(borderRadius && { borderRadius }),
    ...(bgColor && { backgroundColor: bgColor }),
    ...(height && { height }),
    ...(width && { width }),
    ...styleProp,
  };

  if (margin) {
    style = { ...style, margin };
  } else {
    if (mt) style.marginTop = mt;
    if (mb) style.marginBottom = mb;
    if (ml) style.marginLeft = ml;
    if (mr) style.marginRight = mr;
  }

  if (padding) {
    style = { ...style, padding };
  } else {
    if (pt) style.paddingTop = pt;
    if (pb) style.paddingBottom = pb;
    if (pl) style.paddingLeft = pl;
    if (pr) style.paddingRight = pr;
  }

  return (
    <div
      className={classnames(
        styles.container,
        {
          [styles.fullWidth]: fullWidth,
        },
        className,
      )}
      style={style}
      {...props}
    >
      {children}
    </div>
  );
};

Flex.propTypes = {
  direction: PropTypes.oneOf(['row', 'column']),
  align: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
  justify: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
  gap: PropTypes.string,
  wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
  borderRadius: PropTypes.string,
};

Flex.defaultProps = {
  fullWidth: true,
  padding: null,
  direction: 'row',
  align: 'center',
  justify: 'center',
  height: null,
  width: null,
  gap: '0',
  wrap: 'nowrap',
  className: null,
  borderRadius: null,
  bgColor: null,
  mt: null,
  mb: null,
  ml: null,
  mr: null,
  pb: null,
  pt: null,
  pl: null,
  pr: null,
  margin: null,
  style: {},
};

export default Flex;
