import { localTtlStorage, dataURLtoFile } from 'utils';

const getTempForm = () => {
  const tmpForm = JSON.parse(localTtlStorage.getItem('tmpFormOrder', { loadWithoutStructure: false }));
  if (tmpForm) {
    const tmpFormOrder = Object.assign({}, tmpForm);
    tmpFormOrder.imageFiles = [];
    if (tmpForm.imageFiles?.length > 0) {
      for (let file of tmpForm.imageFiles) {
        const fileObject = dataURLtoFile(file.data, file.name);
        tmpFormOrder.imageFiles.push(fileObject);
      }
    }
    return tmpFormOrder;
  }
  return null;
};

export default getTempForm;
