import bugsnag from '../bugsnag';

const handleActiveChat = (currentShopping) => {
  try {
    window.track({
      mixpanel: {
        name: 'Clicked Chat Kustomer DC',
        properties: {
          Slug: currentShopping?.slug,
        },
      },
      googleTagManager: {
        global: {
          name: 'clickedChatKustomerDc',
          properties: {
            slug: currentShopping?.slug,
          },
        },
      },
    });
  } catch (e) {
    bugsnag?.notify(e);
  }
};

export default handleActiveChat;
