import gql from 'graphql-tag';

const PROMOTION_LPR_REGISTRATIONS = gql`
  query lprRegistrations {
    lprRegistrations {
      id
    }
  }
`;

export { PROMOTION_LPR_REGISTRATIONS };
