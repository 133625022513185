import { getShoppingBusinessHours } from 'utils';

const getStoreBusinessHours = (store, splited = false) => {
  return getShoppingBusinessHours({
    shopping: store?.shopping,
    typename: store.__typename,
    splited,
  });
};

export default getStoreBusinessHours;
