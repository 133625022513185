import gql from 'graphql-tag';
import { shortOrderSelect, meSelect } from './helpers/variables';

const CREATE_ORDER = gql`
  mutation createOrder(
    $appsSlug: [String!],
    $shoppingId: ID!,
    $text: String,
    $storesIds: [ID],
    $amount: Float,
    $filesIds: [ID!],
    $addressId: ID,
    $categoryId: ID,
    $deliveryTypeId: ID!,
    $paymentTypeId: ID!,
    $creditCardId: ID,
    $couponName: String,
    $change: Float,
    $fees: [Float!],
    $deliveryAmount: Float,
    $thirdPartyName: String,
    $thirdPartyPhone: String,
  ) {
    createOrder(
      appsSlug: $appsSlug,
      shoppingId: $shoppingId,
      text: $text,
      storesIds: $storesIds,
      amount: $amount,
      filesIds: $filesIds,
      addressId: $addressId,
      categoryId: $categoryId,
      deliveryTypeId: $deliveryTypeId,
      paymentTypeId: $paymentTypeId,
      creditCardId: $creditCardId,
      change: $change,
      fees: $fees,
      couponName: $couponName,
      deliveryAmount: $deliveryAmount,
      thirdPartyName: $thirdPartyName,
      thirdPartyPhone: $thirdPartyPhone,
    ) {
      ${shortOrderSelect}
    }
  }
`;

const UPLOAD_FILE = gql`
  mutation uploadFile($name: String!, $data: String!) {
    uploadFile(name: $name, data: $data) {
      id
    }
  }
`;

const UPLOAD_MULTIPLE_FILES = gql`
  mutation uploadMultipleFiles($files: [Upload!]) {
    uploadMultipleFiles(files: $files) {
      id
    }
  }
`;

const UPLOAD_MULTIPLE_IMAGES_BY_URL = gql`
  mutation uploadMultipleImagesByUrl($images: [ImageInput!]!) {
    uploadMultipleImagesByUrl(images: $images) {
      id
      original
    }
  }
`;

const REGISTER_DEVICE = gql`
  mutation registerDevice($deviceToken: String, $appIdentifier: String, $appVersion: String!, $timeZone: String) {
    registerDevice(
      deviceToken: $deviceToken
      appIdentifier: $appIdentifier
      appVersion: $appVersion
      timeZone: $timeZone
    )
  }
`;

const SAVE_CART = gql`
  mutation saveCart {
    saveCart
  }
`;

const UPDATE_CART = gql`
  mutation updateCart(
    $itemId: ID
    $sku: ID
    $menu: String
    $quantity: Int!
    $price: Float
    $description: String
    $appSlug: String
    $shoppingId: ID
  ) {
    updateCart(
      itemId: $itemId
      sku: $sku
      menu: $menu
      price: $price
      description: $description
      quantity: $quantity
      appSlug: $appSlug
      shoppingId: $shoppingId
    )
  }
`;

const DELETE_CART = gql`
  mutation deleteCart($appSlug: String, $shoppingId: ID!) {
    deleteCart(appSlug: $appSlug, shoppingId: $shoppingId)
  }
`;

const REMOVE_ITEM = gql`
  mutation removeItem($itemId: ID!) {
    removeItem(itemId: $itemId)
  }
`;

const DC_TERM_READ = gql`
  mutation dcTermRead {
    dcTermRead
  }
`;

const FEEDBACK = gql`
  mutation feedback($slug: String!, $items: [FeedbackClientItemInput!]!, $order: ID) {
    feedback(slug: $slug, items: $items, order: $order) {
      id
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $showIntroductionAnything: Boolean
    $birthdate: Date
    $name: String
    $gender: String
    $tokenToValidate: String
    $phoneNumber: String
    $email: String
    $shoppingSlug: String
    $terms: Boolean
    $privacyPolicy: Boolean
  ) {
    updateUser(
      id: $id
      showIntroductionAnything: $showIntroductionAnything
      birthdate: $birthdate
      name: $name
      gender: $gender
      tokenToValidate: $tokenToValidate
      phoneNumber: $phoneNumber
      email: $email
      shoppingSlug: $shoppingSlug
      terms: $terms
      privacyPolicy: $privacyPolicy
    ) {
      ${meSelect}
    }
  }
`;

const CREATE_CREDIT_CARD = gql`
  mutation createCreditCard(
    $token: String!
    $name: String!
    $brand: String!
    $lastNumbers: String!
    $validThru: String!
  ) {
    createCreditCard(token: $token, name: $name, brand: $brand, lastNumbers: $lastNumbers, validThru: $validThru) {
      id
      name
      brand
      lastNumbers
      validThru
    }
  }
`;

const DISABLE_LPR_USER = gql`
  mutation disableLprUser($licensePlate: String!, $creditCardId: ID!) {
    disableLprUser(licensePlate: $licensePlate, creditCardId: $creditCardId)
  }
`;

const SAVE_USER_VIEW_TOKEN = gql`
  mutation saveUserViewToken($partnerSlug: String!) {
    saveUserViewToken(partnerSlug: $partnerSlug) {
      token
    }
  }
`;

const FAVORITE_CHECK_IN = gql`
  mutation favoriteCheckIn($checkInId: ID!) {
    favoriteCheckIn(checkInId: $checkInId)
  }
`;

const UNFAVORITE_CHECK_IN = gql`
  mutation unfavoriteCheckIn($checkInId: ID!) {
    unfavoriteCheckIn(checkInId: $checkInId)
  }
`;

const SAVE_USER_CHECK_IN = gql`
  mutation saveUserCheckIn($checkInId: ID!, $shoppingId: ID!, $numDependents: Int) {
    saveUserCheckIn(checkInId: $checkInId, shoppingId: $shoppingId, numDependents: $numDependents) {
      id
      user {
        name
      }
      checkedInAt
      counter
      code
      usedAt
      hasCode
      numDependents
    }
  }
`;

const USE_CHECK_IN = gql`
  mutation useCheckIn($id: ID!, $storeCode: String) {
    useCheckIn(id: $id, storeCode: $storeCode) {
      id
      usedAt
    }
  }
`;

const SAVE_USER_VIEW_HISTORY = gql`
  mutation saveUserViewHistory($entity: String!, $entityId: ID!, $viewSlug: String) {
    saveUserViewHistory(entity: $entity, entityId: $entityId, viewSlug: $viewSlug) {
      id
    }
  }
`;

const SAVE_CREDIT_CARD = gql`
  mutation saveCreditCard(
    $token: String!
    $name: String!
    $brand: String!
    $lastNumbers: String!
    $validThru: String!
  ) {
    saveCreditCard(token: $token, name: $name, brand: $brand, lastNumbers: $lastNumbers, validThru: $validThru) {
      id
      name
      brand
      lastNumbers
      validThru
    }
  }
`;

const SAVE_USER_VIP = gql`
  mutation saveUserVip($shoppingSlug: String!) {
    saveUserVip(shoppingSlug: $shoppingSlug) {
      id
      plan
    }
  }
`;

const DELETE_CREDIT_CARD = gql`
  mutation disableCreditCard($id: ID!) {
    disableCreditCard(id: $id)
  }
`;

const ACTIVATE_WIFI_MULTI = gql`
  mutation activateWifiMulti {
    activateWifiMulti {
      status
      startDate
      endDate
    }
  }
`;

const RENEW_WIFI_MULTI = gql`
  mutation renewWifiMulti {
    renewWifiMulti {
      startDate
      endDate
    }
  }
`;

export {
  CREATE_ORDER,
  UPLOAD_FILE,
  UPLOAD_MULTIPLE_FILES,
  UPLOAD_MULTIPLE_IMAGES_BY_URL,
  REGISTER_DEVICE,
  SAVE_CART,
  UPDATE_CART,
  DELETE_CART,
  REMOVE_ITEM,
  DC_TERM_READ,
  FEEDBACK,
  UPDATE_USER,
  CREATE_CREDIT_CARD,
  DISABLE_LPR_USER,
  SAVE_USER_VIEW_TOKEN,
  FAVORITE_CHECK_IN,
  UNFAVORITE_CHECK_IN,
  SAVE_USER_CHECK_IN,
  USE_CHECK_IN,
  SAVE_USER_VIEW_HISTORY,
  SAVE_CREDIT_CARD,
  SAVE_USER_VIP,
  DELETE_CREDIT_CARD,
  ACTIVATE_WIFI_MULTI,
  RENEW_WIFI_MULTI,
};
