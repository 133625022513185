import { SHOPPING, CURRENT_SHOPPING } from 'services/apollo/queries';
import { bugsnag } from 'utils';

const newProperties = [
  'address',
  'map',
  'hasMarketplace',
  'hasPlayApp',
  'hasLocker',
  'logo',
  'promotionsContacts',
  'showDirectSelling',
  'color',
  'hasAnythingOrder',
  'footerApps',
  'promotions',
];

const getShopping = () => {
  const currentShopping = multiStorage.getItem('shopping');

  if (
    typeof currentShopping === 'string' &&
    newProperties.filter((item) => currentShopping.indexOf(item) === -1).length <= 0
  ) {
    return JSON.parse(currentShopping);
  }
  return null;
};

const setNewShoppingProperties = async (client) => {
  const currentShopping = multiStorage.getItem('shopping');
  if (typeof currentShopping === 'string') {
    try {
      const jsonShopping = JSON.parse(currentShopping);
      if (
        jsonShopping !== null &&
        typeof jsonShopping === 'object' &&
        newProperties.filter((item) => jsonShopping[item] === -1).length > 0
      ) {
        const {
          data: { shopping },
        } = await client.query({
          query: SHOPPING,
          variables: { slug: JSON.parse(currentShopping).slug },
        });
        multiStorage.setItem('shopping', JSON.stringify(shopping));
        try {
          window.identifyShopping();
        } catch (e) {
          bugsnag?.notify(e);
        }
        client.writeQuery({ query: CURRENT_SHOPPING, data: { currentShopping: shopping } });
      }
    } catch (e) {
      bugsnag?.notify(e);
      multiStorage.removeItem('shopping');

      try {
        window.unregister({
          mixpanel: {
            name: 'Shopping ID',
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }

      try {
        window.unregister({
          mixpanel: {
            name: 'Shopping Name',
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }

      try {
        window.track({
          googleTagManager: {
            global: {
              name: 'clearShopping',
            },
          },
        });
      } catch (e) {
        bugsnag?.notify(e);
      }
    }
  }
};

export { setNewShoppingProperties };
export default getShopping;
